<template>
  <div id="app">
    <router-view/>
    <!-- 文件上传队列状态 -->
    <uploadListPage class="uploadPageBox"></uploadListPage>
    <!-- <login-view :visible="visible" :hasUser="hasUser" @loginSuccess="success"></login-view> -->
  </div>
</template>

<script>
import store from './store'
import uploadListPage from '@/components/uploadFileDialog/uploadList'
// import loginView from '@/components/forLogin'
export default {
  name: 'App',
  components: {
    uploadListPage
  },
  data() {
    return {
      visible: false,
      hasUser: false
    }
  },
  computed: {
    userStatus: () => {
      return store.getters.userStatus
    }
  },
  watch: {
    // userStatus(val, oldVal) {
    //   debugger
    //   if (!val) {

    //   }
    // }
    // userStatus: {
    //   handler(val, oldVal) {
    //     const isToken = sessionStorage.getItem('isToken')
    //     // 通过链接或刷新触发
    //     if (oldVal === undefined) {
    //       if (val === '' && !isToken) {

    //       }
    //     } else { // token状态改变触发
    //       if (val !== '1') {
    //         this.visible = true
    //       }
    //     }
    //   },
    //   immediate: true
    // }
  },
  created() {
    sessionStorage.setItem('isToken', true)
  },
  mounted() {
    const urlInfo = JSON.parse(sessionStorage.getItem('urlInfo'))
    if (urlInfo) {
      // this.popStatus()
      this.hasUser = true
    } else {
      this.hasUser = false
      console.log('没账号啊')
    }
    // if (!this.userStatus && !urlInfo) {
    //   this.visible = true
    // }
  },
  beforeUpdate() {
    console.log('dsdsd')
  },
  methods: {
    // success() {
    //   this.visible = false
    //   this.$router.push({
    //     path: '/drive/home'
    //   })
    // }
  }
}
</script>
<style>
.uploadPageBox {
    position: fixed;
    z-index: 100;
    bottom: 30px;
    right: 30px;
  }
</style>