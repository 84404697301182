import { logout, getUserInfo, getAllUsers } from '@/api/login'
import { getToken, setToken, removeToken, setTokenPower, removeTokenPower } from '@/utils/auth'
import { getUrlKey } from '@/utils/index'
import { getUser, getDDusers } from '@/api/user'
const Base64 = require('js-base64').Base64
const user = {
  state: {
    userId: '',
    userIp: '',
    status: '',
    systemCode: '',
    token: getToken(),
    name: '',
    nameEn: '',
    workcode: '',
    avatar: '',
    introduction: '',
    roles: [],
    setting: {
      articlePlatform: []
    },
    actionUrl: [],
    permissions: [], // 权限tags列表
    permissionTag: false,
    userStatus: '', // 用来记录token是有过期，提示用户重登
    allUsers: [],
    // 部门id
    departmentId: null,
    // 部门名称
    udName: '',
    // 部门父级完整id链
    udId: '',
    headImageUrl: '',
    // 选择上传的文件
    uploadFileList: [],
    workcodeList: []
  },
  mutations: {
    SET_ALLUSERS: (state, userlist) => {
      state.allUsers = userlist
    },
    SET_USERSTATUS: (state, status) => {
      state.userStatus = status
    },
    SET_USERID: (state, userId) => {
      state.userId = userId
    },
    SET_USERIP: (state, userIp) => {
      state.userIp = userIp
    },
    SET_SYSTEM_CODE: (state, code) => {
      state.systemCode = code
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_INTRODUCTION: (state, introduction) => {
      state.introduction = introduction
    },
    SET_SETTING: (state, setting) => {
      state.setting = setting
    },
    SET_STATUS: (state, status) => {
      state.status = status
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_WORKCODE: (state, workcode) => {
      state.workcode = workcode
    },
    SET_NAME_EN: (state, name) => {
      state.nameEn = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_ACTIVE: (state, urlList) => {
      state.actionUrl = urlList
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_PERMISSIONS_TAG: (state, value) => {
      state.permissionTag = value
    },
    SET_DEPARTMENT_INFO: (state, obj) => {
      state.departmentId = obj.departmentId
      state.udName = obj.udName
      state.udId = obj.udId
    },
    SET_HEADiMAGE: (state, data) => {
      state.headImageUrl = data
    },
    SET_UPLOAD_FILE_LIST: (state, data) => {
      state.uploadFileList = data
    },
    SET_WORK_CODE_LIST: (state, data) => {
      state.workcodeList = data
    }
  },
  actions: {
    updateUploadList({ commit, state }, file) {
      commit('SET_UPLOAD_FILE_LIST', file)
    },
    SetUserStatus({ commit }, status) {
      commit('SET_USERSTATUS', status)
    },
    // 获取URL信息
    LoginByUsername({ commit }) {
      const urlInfo = JSON.parse(Base64.decode(getUrlKey('code')))
      return new Promise((resolve, reject) => {
        if (urlInfo) {
          setToken(urlInfo.token)
          setTokenPower(JSON.stringify(urlInfo))
          sessionStorage.setItem('urlInfo', JSON.stringify(urlInfo))
          // sessionStorage.setItem('isToken', true)
          commit('SetUserStatus', '1')
          resolve(urlInfo)
        } else {
          reject(new Error('No request to URL parameters'))
        }
      })
    },

    // 获取用户信息
    GetUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo().then(response => {
          if (!response.data) {
            reject(new Error('Verification failed, please login again.'))
          }
          const data = response.data
          const urlInfo = JSON.parse(sessionStorage.getItem('urlInfo'))
          commit('SET_USERID', urlInfo.userId)
          commit('SET_USERIP', urlInfo.ip)
          commit('SET_SYSTEM_CODE', urlInfo.systemCode)
          commit('SET_TOKEN', urlInfo.token)
          commit('SET_NAME', data.name)
          commit('SET_WORKCODE', data.workcode)
          commit('SET_NAME_EN', data.englishName)
          commit('SET_ACTIVE', data.actionUrl)
          commit('SET_USERSTATUS', '1')
          // sessionStorage.setItem('isToken', true)
          // tag权限处理
          const permission = response.data.userRightVoList.filter(item => item.tag)
          commit('SET_PERMISSIONS', permission) // 账号拥有的权限tag列表
          commit('SET_PERMISSIONS_TAG', true)
          // if (data.roles && data.roles.length > 0) { // 验证返回的roles是否是一个非空数组
          //   commit('SET_ROLES', data.roles)
          // } else {
          //   reject('getInfo: roles must be a non-null array!')
          // }

          // commit('SET_AVATAR', data.avatar)
          // commit('SET_INTRODUCTION', data.introduction)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('SET_USERID', '')
          commit('SET_USERIP', '')
          commit('SET_SYSTEM_CODE', '')
          commit('SET_TOKEN', '')
          commit('SET_NAME', '')
          commit('SET_NAME_EN', '')
          sessionStorage.removeItem('urlInfo')
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        sessionStorage.removeItem('urlInfo')
        removeToken()
        removeTokenPower()
        resolve()
      })
    },

    // 动态修改权限
    ChangeRoles({ commit, dispatch }, role) {
      return new Promise(resolve => {
        commit('SET_TOKEN', role)
        setToken(role)
        getUserInfo(role).then(response => {
          const data = response.data
          commit('SET_ROLES', data.roles)
          commit('SET_NAME', data.name)
          commit('SET_AVATAR', data.avatar)
          commit('SET_INTRODUCTION', data.introduction)
          dispatch('GenerateRoutes', data) // 动态修改权限后 重绘侧边菜单
          resolve()
        })
      })
    },

    // 全部账号
    GetUsers({ commit }) {
      return new Promise((resolve, reject) => {
        getAllUsers({ pageNum: 1, pageSize: 10000, state: 1, status: 1 }).then(response => {
          console.log(response)
          if (response.code === '000000') {
            commit('SET_ALLUSERS', response.data.list)
            resolve(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 该账号的信息（部门id、部门父级链、部门名称)
    GetUsersDpInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        // 8527 state.userId
        getAllUsers({ userIds: state.userId }).then(response => {
          if (response.code === '000000' && response.data.list && response.data.list.length > 0) {
            const dpInfo = response.data.list[0]
            commit('SET_DEPARTMENT_INFO', {
              departmentId: dpInfo.departmentId,
              udName: dpInfo.udName,
              udId: dpInfo.udId
            })
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 获取用户头像
    GetHeadImage({ commit }, { workcode }) {
      return new Promise((resolve, reject) => {
        getUser({ workcode: workcode }).then(response => {
          if (response.code === '000000' && response.data.length > 0) {
            commit('SET_HEADiMAGE', response.data[0].headImgName)
            resolve()
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    // 获取全部工号数据
    GetWorkcode({ commit }) {
      return new Promise((resolve, reject) => {
        getDDusers(0).then(response => {
          if (response.code === '000000') {
            commit('SET_WORK_CODE_LIST', response.data.filter(item => item.workcode !== 'PZWG0000'))
            resolve(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
  }
}

export default user
