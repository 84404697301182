const getters = {
  language: state => state.app.language,
  size: state => state.app.size,
  device: state => state.app.device,
  catalog: state => state.app.catalog,
  catalogTree: state => state.app.catalogTree,
  catalogWiki: state => state.app.catalogWiki,
  wikiMenuItem: state => state.app.wikiMenuItem,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  nameEn: state => state.user.nameEn,
  workcode: state => state.user.workcode,
  introduction: state => state.user.introduction,
  status: state => state.user.status,
  roles: state => state.user.roles,
  setting: state => state.user.setting,
  permission_routers: state => state.permission.routers,
  addRouters: state => state.permission.addRouters,
  tagRouters: state => state.permission.tagRouters,
  userId: state => state.user.userId,
  userIp: state => state.user.userIp,
  systemCode: state => state.user.systemCode,
  actionUrl: state => state.user.actionUrl,
  tagsList: state => state.tags.tagsList,
  permissions: state => state.user.permissions,
  permissionTag: state => state.user.permissionTag,
  userStatus: state => state.user.userStatus,
  allUsers: state => state.user.allUsers,
  mdStr: state => state.app.mdStr,
  mdChange: state => state.app.mdChange,
  needChange: state => state.app.needChange,
  commentChange: state => state.app.commentChange,
  delComments: state => state.app.delComments,
  departmentId: state => state.user.departmentId,
  udName: state => state.user.udName,
  udId: state => state.user.udId,
  chapter_departList: state => state.user.chapter_departList,
  headImageUrl: state => state.user.headImageUrl,
  uploadFileList: state => state.user.uploadFileList
}
export default getters
