import loginRequest from '@/utils/login-request'
import request from '@/utils/request'

// 钉钉所有员工
export function getDDusers(id) {
  return loginRequest({
    url: `user/get/dingTaikUser/${id}`,
    method: 'get'
  })
}
// 钉钉部门架构
export function getDepartTree() {
  return loginRequest({
    url: `/department/get/${process.env.VUE_APP_COMPANY_ID}/tree`,
    method: 'get'
  })
}
// 所有员工
export function apiGetAllUser(params) {
  return loginRequest({
    url: '/api/api/users',
    method: 'get',
    params
  })
}
// 查询用户头像
export function getUser(params) {
  return request({
    url: '/user/getUser',
    method: 'get',
    params: params
  })
}
// 更新用户头像信息
export function updateUserInfo(params) {
  return request({
    url: '/user/updateUserInfo',
    method: 'post',
    data: params
  })
}
// 上传用户头像
export function uploadHeadImg(params) {
  return request({
    url: '/user/uploadHeadImg',
    method: 'post',
    data: params
  })
}

