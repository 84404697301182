import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

// 权限系统完整token，用于处理通过链接进入系统页面时，sessionStorage中没有userInfo信息
const powerToken = window.location.host

export function getTokenPower() {
  return Cookies.get(powerToken)
}

export function setTokenPower(token) {
  const inHalfADay = 0.5 // 12小时失效时间
  return Cookies.set(powerToken, token, {
    expires: inHalfADay
  })
}

export function removeTokenPower() {
  return Cookies.remove(powerToken)
}

// 获取权限系统登陆存储的cookie, 如果取到，则将systemCode（权限系统存储的cookie中没有子系统的systemCode）塞进去，走有code进入系统的逻辑
const domainStr = process.env.NODE_ENV === 'production' ? '.suntekcorps.com' : window.location.hostname
export function getPowerSystemCookie() {
  return Cookies.get('Power-Admin-Info', { domain: domainStr })
}
