import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'// progress bar style
import { getPowerSystemCookie } from '@/utils/auth'

NProgress.configure({ showSpinner: false })// NProgress Configuration

const judge = (to, next, hasCode) => {
  // 实时更新urlInfo(旧代码中有用到里边的数据)
  const powerToken = getPowerSystemCookie()
  if (powerToken) {
    const user = JSON.parse(powerToken)
    const newInfo = {
      userId: user.userInfo.userId,
      username: user.userInfo.username,
      systemCode: process.env.VUE_APP_SYSTEM_CODE,
      token: user.token
    }
    sessionStorage.setItem('urlInfo', JSON.stringify(newInfo))
    if (store.getters.userId === '') {
      store.dispatch('GetUserInfo').then(() => { // 拉取user_info
        store.dispatch('GenerateRoutes', {}).then(() => { // 根据roles权限生成可访问的路由表
          router.addRoutes(store.getters.addRouters) // 动态添加可访问路由表
          // if (window.location.pathname && window.location.pathname.length > 2) {
          //   router.push({ path: window.location.pathname })
          // } else {
            if (hasCode) {
              router.push({ path: '/' })
            } else {
              next({ ...to, replace: true }) // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
            }
          // }
        })
        store.dispatch('GetUsersDpInfo')
      }).catch((err) => {
        store.dispatch('LogOut').then(() => {
          Message.error(err)
          NProgress.done()
          window.close()
        })
      })
    } else {
      next()
    }
  } else {
    store.dispatch('LogOut').then(() => {
      NProgress.done()
      window.location.href = `${process.env.VUE_APP_POWER_SYSTEM_URL}?redirect=${window.location.href}`
    })
  }
}
router.beforeEach((to, from, next) => {
  NProgress.start() // start progress bar
  if (window.location.href.indexOf('?code=') !== -1) {
    const NProgressInfo = JSON.parse(sessionStorage.getItem('urlInfo'))
    if (NProgressInfo) {
      judge(to, next, true)
    } else {
      store.dispatch('LoginByUsername').then(res => {
      // router.push({ path: window.location.pathname })
        router.push({ path: to.path }).catch(e => {})
      }).catch(err => {
        Message.error(err)
      })
    }
  } else if (getPowerSystemCookie()) {
    const NProgressInfo = JSON.parse(sessionStorage.getItem('urlInfo'))
    if (NProgressInfo) {
      judge(to, next)
    } else {
      // 取权限系统登陆时存的顶级域名cookie,组装成code格式
      const powerToken = getPowerSystemCookie()
      const user = JSON.parse(powerToken)
      const newInfo = {
        userId: user.userInfo.userId,
        username: user.userInfo.username,
        systemCode: process.env.VUE_APP_SYSTEM_CODE,
        token: user.token
      }
      sessionStorage.setItem('urlInfo', JSON.stringify(newInfo))
      store.dispatch('GetUserInfo').then(() => { // 拉取user_info
        store.dispatch('GenerateRoutes', {}).then(() => { // 根据roles权限生成可访问的路由表
          router.addRoutes(store.getters.addRouters) // 动态添加可访问路由表
          next({ ...to, replace: true }) // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
        })
        store.dispatch('GetUsersDpInfo')
      }).catch((err) => {
        store.dispatch('LogOut').then(() => {
          Message.error(err)
          NProgress.done()
          window.close()
        })
      })
    }
  } else {
    window.location.href = `${process.env.VUE_APP_POWER_SYSTEM_URL}?redirect=${window.location.href}`
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
