import requestPHP from '@/utils/requestPHP'
import request from '@/utils/request'
import requestExport from '@/utils/requestExport'

// 上传 文件
export function uploadFile(params, file) {
  return requestPHP({
    url: '/api.php',
    method: 'post',
    params: params,
    data: file
  })
}

// 上传文件 增加节点
export function uploadChapter(params) {
  return request({
    url: '/uploadPhp/uploadSpace',
    method: 'post',
    data: params
  })
}

// 批量上传文件 增加节点
export function uploadChapterBatch(params) {
  return request({
    url: '/uploadPhp/uploadSpaceBatch',
    method: 'post',
    data: params
  })
}

// 删除文件
export function deleteFile(params, data) {
  return requestPHP({
    url: '/api.php',
    method: 'post',
    params: params,
    data: data
  })
}

// 新建文档
export function newFile(params) {
  return requestPHP({
    url: '/api.php',
    method: 'get',
    params: params
  })
}

// 文件下载
export function downloadFile(params) {
  return requestExport({
    url: '/index.php',
    method: 'get',
    params: params
  })
}
