import request from '@/utils/request'

// 我的空间 收藏(取消收藏)文章
export function collectSpace(params) {
  return request({
    url: '/collect/collectChapter',
    method: 'post',
    data: params
  })
}

// 查询收藏的节点文章
export function getCollectList(params) {
  return request({
    url: '/collect/chapterList',
    method: 'get',
    params: params
  })
}
