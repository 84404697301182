import { constantRouterMap, routerMap } from '@/router'
import { bussinessTree } from '@/api/login'
import Layout from '@/views/layout/Layout'
import store from '@/store'

// 根据数据装载成路由格式
function formatRoutes(aMenu) {
  const tagRouter = []
  const aRouter = []
  let returnData = {}
  const language = store.getters.language
  for (const i in aMenu) {
    const childrenMenu = aMenu[i].childUserRight
    let routerItem = {}
    const childRoute = []
    for (const j in childrenMenu) {
      let route = {}
      let tagItem = {}
      if (childrenMenu.length > 1) {
        routerItem = {
          path: `/${aMenu[i].umUrl.split('/')[1]}`,
          component: Layout,
          redirect: aMenu[i].umUrl,
          alwaysShow: true,
          meta: {
            title: language === 'zh' ? aMenu[i].umTitle : aMenu[i].umEnTitle,
            icon: 'tree'
          }
        }
        route = {
          path: `${childrenMenu[j].urUrl}/index`,
          component: routerMap[childrenMenu[j].urUrl.split('/')[1]],
          name: childrenMenu[j].urEnName,
          meta: {
            title: language === 'zh' ? childrenMenu[j].urName : childrenMenu[j].urEnName,
            icon: 'list'
          }
        }
      } else {
        routerItem = {
          path: `${childrenMenu[j].urUrl}`,
          component: Layout,
          redirect: `${childrenMenu[j].urUrl}/index`
        }
        route = {
          path: 'index',
          component: routerMap[childrenMenu[j].urUrl.split('/')[1]],
          name: childrenMenu[j].urEnName,
          meta: {
            title: language === 'zh' ? childrenMenu[j].urName : childrenMenu[j].urEnName,
            icon: 'list'
          }
        }
      }
      tagItem = {
        path: `${childrenMenu[j].urUrl}/index`,
        name: childrenMenu[j].urEnName,
        nameZh: childrenMenu[j].urName
      }
      tagRouter.push(tagItem)
      childRoute.push(route)
      routerItem.children = childRoute
    }
    aRouter.push(routerItem)
  }
  returnData = {
    aRouter: aRouter,
    tagRouter: tagRouter
  }
  console.log(returnData)
  return returnData
}

const permission = {
  state: {
    routers: [],
    addRouters: [],
    tagRouters: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers.aRouter
      state.routers = constantRouterMap.concat(routers.aRouter)
      state.tagRouters = routers.tagRouter.concat([{ name: 'home', nameZh: '首页', path: '/index' }])
    }
  },
  actions: {
    GenerateRoutes({ commit }) {
      return new Promise((resolve, reject) => {
        bussinessTree(store.getters.systemCode).then(response => {
          const router = formatRoutes(response.data)
          const accessedRouters = router
          commit('SET_ROUTERS', accessedRouters)
          resolve()
        })
          .catch(error => {
            console.log(error)
            reject(error)
          })

        // commit('SET_ROUTERS', asyncRouterMap)
        // resolve()
      })
    }
  }
}
export default permission
