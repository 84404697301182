<template>
  <div class="navbar-new">
    <!-- <searchTop /> -->
    <!-- <el-menu :default-active="activeIndex"
             class="homemenu"
             mode="horizontal"
             text-color="#000"
             active-text-color="#409EFF"
             @select="handleSelect">
      <el-menu-item index="1">首页</el-menu-item>
      <el-submenu index="2">
        <template slot="title">部门</template>
        <el-menu-item :index="'2-'+ item.id" v-for="item in departement" :key="item.id" @click="jumpSpaceDetail(item.moduleId)">{{item.moduleName}}</el-menu-item>
      </el-submenu>
    </el-menu> -->
    <div class="user-container">
      <!-- 进入页面去请求账号的详细信息 -->
      <el-popover
        placement="bottom"
        width="224"
        trigger="click">
        <div class="user-setting">
          <div class="user-setting-top">
            <el-image v-if="headImageUrl" class="userPhoto" :src="headImageUrl" title="个人信息" alt="加载失败" />
            <div v-else class="userImg" title="个人信息" alt="加载失败">{{$store.getters.name.length > 2 ? $store.getters.name.substring(1,$store.getters.name.length) : $store.getters.name }}</div>
            <span>{{$store.getters.name}}</span>
            <p>{{$store.getters.udName}}</p>
          </div>
          <ul class="user-setting-bottom">
            <li @click="editUser"><span>编辑个人信息</span></li>
            <li @click="logout"><span>退出登录</span></li>
          </ul>
        </div>
        <div slot="reference">
          <el-image v-if="headImageUrl" class="userPhoto" :src="headImageUrl" title="个人信息" alt="加载失败" @error="error" />
          <div v-else class="userImg" title="个人信息" alt="加载失败">{{$store.getters.name.length > 2 ? $store.getters.name.substring(1,$store.getters.name.length) : $store.getters.name }}</div>
        </div>
      </el-popover>
    </div>
    <user ref="user" />
  </div>
</template>

<script>
import user from '@/views/user/index'
// import searchTop from '@/components/searchTop'
import { getUser } from '@/api/user'
export default {
  components: {
    // searchTop,
    user
  },
  data() {
    return {
      activeIndex: '1',
      departement: [
        { id: 1, name: 'IT部' },
        { id: 2, name: '财务部' },
        { id: 3, name: '行政部' },
        { id: 4, name: '运营部' }
      ]
    }
  },
  created() {
    getUser({ workcode: this.$store.getters.workcode }).then(res => {
      if (res.data.length > 0) {
        this.$store.commit('SET_HEADiMAGE', res.data[0].headImgName)
      }
    }).catch(error => { console.log(error) })
  },
  computed: {
    headImageUrl() {
      return this.$store.getters.headImageUrl ? `${process.env.VUE_APP_BASE_API}/user/downloadHeadImg?fileName=` + this.$store.getters.headImageUrl + `&${this.code}` : null
    },
    code() {
      const urlInfo = JSON.parse(sessionStorage.getItem('urlInfo'))
      const code = 'token=' + urlInfo.token + '&hostIp=' + urlInfo.ip + '&systemCode=' + urlInfo.systemCode + '&sfcLang=en'
      return code
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
    },
    jumpSpaceDetail(id) {
      console.log(id)
    },
    logout() {
      this.$confirm('退出登录后，你将无法收到所有帐号的通知', '确认退出登录所有帐号吗？', {
        confirmButtonText: '退出',
        cancelButtonText: '返回',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut')
        // this.$router.push({ path: '/login' })
        window.close()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    editUser() {
      this.$refs.user.openDialog()
    },
    error() {
      this.$store.commit('SET_HEADiMAGE', '')
    }
  }
}
</script>

<style lang='scss' scoped>
.navbar-new {
  // display: flex;
  // justify-content: space-between;
  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  .homemenu {
    width: 200px;
  }
  .user-container {
    float: right;
    margin-right: 20px;
    cursor: pointer;
    .userPhoto {
      float: left;
      width: 32px;
      height: 32px;
      margin: 15px 10px 0 0;
      border-radius: 50%;
      font-size: 12px;
    }
    .el-popover__reference {
      height: 60px;
    }
    .userImg {
      float: left;
      width: 32px;
      height: 32px;
      margin: 15px 10px 0 0;
      background-color: #f4870a;
      color: #ffffff;
      font-size: 12px;
      border-radius: 50%;
      line-height: 32px;
      text-align: center;
    }
  }
}
.user-setting {
  &-top {
    padding: 24px 8px 5px;
    text-align: center;
    border-bottom: 1px solid #dee0e3;
    .userImg {
      width: 40px;
      height: 40px;
      margin: 0 auto 10px;
      background-color: #f4870a;
      color: #ffffff;
      font-size: 12px;
      border-radius: 50%;
      line-height: 40px;
      text-align: center;
    }
    .userPhoto {
      margin: 0 auto 10px;
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      font-size: 12px;
    }
    p {
      margin: 10px 0;
    }
  }
  &-bottom {
    list-style-type: none;
    padding: 0 5px;
    li {
      padding: 2px 0;
      border-bottom: 1px solid #dee0e3;
      &:last-child {
        border-bottom: none;
      }
      span {
        display: block;
        padding: 8px 10px;
        &:hover {
          background-color: #1f232914;
          border-radius: 2px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
