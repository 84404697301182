<template>
  <div v-if="visible">
    <el-dialog
      :visible="true"
      width="400px"
      title="重命名"
      v-ismove
      :append-to-body="true"
      :close-on-click-modal="false"
      :before-close="closeTab">
      <div style="min-height:200px;padding-bottom:40px;position:relative">
        <el-input placeholder="请输入名称" v-model="folderName" clearable size="small" style="margin-bottom: 10px">
        </el-input>
        <div style="text-align:right;position:absolute;bottom:0px;width:100%">
          <el-button size="small" @click="closeTab">取消</el-button>
          <el-button v-loading="isLoading" size="small" type="primary" :disabled="!folderName" @click="submitUpdate">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { reNameSpace } from '@/api/space'

export default {
  data() {
    return {
      folderName: '',
      isLoading: false
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: null
    },
    name: {
      type: String,
      default: () => ''
    }
  },
  watch: {
    visible: {
      handler(newVal) {
        if (newVal) {
          this.folderName = this.name
        } else {
          this.folderName = ''
        }
      },
      deep: true
    }
  },
  methods: {
    closeTab() {
      this.folderName = ''
      this.$emit('closeTab')
    },
    submitUpdate() {
      this.isLoading = true
      const params = {
        id: this.id,
        title: this.folderName,
        username: this.$store.getters.name
      }
      reNameSpace(params).then((res) => {
        this.isLoading = false
        if (res.code === '000000') {
          this.$message({
            message: res.message,
            type: 'success',
            duration: 1400
          })
          this.folderName = ''
          this.$emit('renameSuccess')
        } else {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 1400
          })
        }
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    }
  }
}
</script>
