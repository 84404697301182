<template>
  <!-- 选择人员，查询选择，或根据部门批量选择 -->
  <div v-if="visible">
    <el-dialog :visible="true" :append-to-body="true" title="选择人员" :close-on-click-modal="false" :before-close="closeTab" width="1000px">
      <el-row :gutter="20" style="height: 400px">
        <el-col :span="8" style="height: 100%; overflow: auto;">
          <el-row>
            <el-select v-model="name"
              remote
              :remote-method="remoteMethod"
              :loading="loading"
              filterable
              reserve-keyword
              value-key="username"
              size="small"
              placeholder="请输入姓名查询"
              style="margin-bottom:10px;width: 100%;padding-right: 16px;"
              @change="changeName">
              <el-option
                v-for="(item, uIndex) in userList"
                :key="uIndex"
                :label="item.name+'('+item.username+')'"
                :value="item"
                :disabled="item.username === createUser">
              </el-option>
            </el-select>
          </el-row>
          <div class="selected-main">
            <div class="member-item" v-for="(item, index) in selectUser" :key="index">
              <span v-if="item.dpName" class="member-icon" style="background:#3370ff"><i class="el-icon-share"></i></span>
              <span v-else class="member-icon">{{ item.name }}</span>
              <span class="member-name">{{ item.dpName ? item.dpName : item.name}}</span>
              <i class="el-icon-close" @click="handleClose(index, item)"></i>
            </div>
          </div>
        </el-col>
        <el-col :span="16" class="right-dp-box">
          <el-row style="border: 1px solid #DCDFE6; border-radius: 4px; height: 400px;">
            <el-col :span="12" style="height: 100%; overflow: auto;">
              <el-tree
                :props="treeProps"
                :data="departTree"
                show-checkbox
                :check-strictly="true"
                :expand-on-click-node="false"
                :default-checked-keys="defaultCheckedList"
                @check-change="handleCheckChange"
                @check="handleCheck"
                @node-expand="handleExpand"
                @node-click="nodeClick">
              </el-tree>
            </el-col>
            <el-col class="table-content" :span="12">
              <el-table ref="dataTable" :data="checkedList" border @selection-change="selectChange" size="mini" height="398">
                <el-table-column type="selection" :selectable="checkSelectable" width="50"></el-table-column>
                <el-table-column label="姓名" prop="name" align="center"></el-table-column>
                <el-table-column label="工号" prop="username" align="center"></el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <div slot="footer" style="margin-top:2em">
        <el-button size="small" @click="closeTab">取消</el-button>
        <el-button type="primary" size="small" @click="save">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getDDusers, getDepartTree } from '@/api/user'

export default {
  name: 'userConf',
  data() {
    return {
      name: null,
      userList: [], // 左侧查询-下拉框
      selectUser: [], // 左侧展示选中人员
      checkedList: [], // 部门下的人员
      needChange: true, // 手动修改选中状态，会触发table选择的change事件，用来阻止此行为
      // checked: [],
      loading: false,
      departTree: [],
      treeProps: {
        label: 'udName',
        children: 'children',
        disabled: 'disabled',
        isLeaf: 'isLeaf'
      },
      defaultCheckedList: [],
      disableDpIdList: [],
      disabledUserList: []
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    permissionUserList: {
      type: Array,
      default: function() {
        return []
      }
    },
    createUser: {
      type: String,
      default: ''
    }
  },
  watch: {
    'visible'(val) {
      if (val) {
        this.getDepart()
        // this.selectUser = JSON.parse(JSON.stringify(this.permissionUserList))
        // this.selectUser.forEach(item => {
        //   item.name = item.username
        //   item.username = item.workcode
        // })
        this.disableDpIdList = this.permissionUserList.filter(el => el.departmentId).map(item => item.departmentId)
        this.disabledUserList = this.permissionUserList.filter(el => el.workcode).map(item => item.workcode)
      }
    }
  },
  computed: {
    allUser: function() {
      return this.$store.getters.allUsers
    }
  },
  mounted() {
    // this.getAllusers()
    if (this.$store.getters.allUsers.length === 0) {
      this.$store.dispatch('GetUsers')
    }
  },
  methods: {
    // 获取部门树形数据
    getDepart() {
      this.departTree = []
      getDepartTree().then(res => {
        if (res.code === '000000') {
          this.departTree = res.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 根据部门获取人员
    getDepartPerson(id) {
      const _this = this
      getDDusers(id).then(res => {
        if (res.code === '000000') {
          // 处理左侧人员，在右侧表格中选中的效果
          this.checkedList = res.data
          // this.needChange = true
          this.$nextTick(() => {
            this.checkedList.forEach(item => {
              console.log(_this.selectUser)
              const site = this.findInArr(item.username, _this.selectUser)
              if (site !== -1) {
                // 在此修改选中状态会主动触发，selectChange方法，通过标记字段阻止此行为
                this.needChange = false
                this.$refs.dataTable.toggleRowSelection(item, true)
              }
            })
          })
          // 延时将needChange置为true
          setTimeout(() => {
            this.needChange = true
          }, 200)
        }
      }).catch(() => {
        this.checkedList = []
      })
    },
    // 已经是协作人的人不能再选中
    checkSelectable(row) {
      return !this.disabledUserList.includes(row.workcode)
    },
    remoteMethod(val) {
      if (val === '') {
        this.userList = []
      } else {
        this.loading = true
        setTimeout(() => {
          this.userList = this.allUser.filter(item => {
            return item.name.indexOf(val) > -1
          })
          this.loading = false
        }, 200)
      }
    },
    changeName() {
      console.log(this.name)
      let needUpdate = false
      // 查重
      if (this.selectUser.length === 0) {
        this.selectUser.push(this.name)
        needUpdate = true
      } else {
        const find = this.selectUser.filter(item => {
          return item.name === this.name.name && item.username === this.name.username
        })
        if (find.length === 0) {
          this.selectUser.push(this.name)
          needUpdate = true
        }
      }
      // 判断右侧表格是否含有该数据，处理显示
      if (needUpdate) {
        this.checkedList.forEach(el => {
          if (this.name.name === el.name && this.name.username === el.username) {
            this.$refs.dataTable.toggleRowSelection(el, true)
          }
        })
      }
      this.name = null
    },
    selectChange(val) {
      if (!this.needChange) {
        this.needChange = true
        return
      }
      // 处理从选中-到取消的情况，删掉左侧对应数据（checkedList中的数据从左侧删掉）
      this.checkedList.forEach(item => {
        if (val.length > 0) {
          // 选中的值处理左侧选中
          const site = this.findInArr(item.username, val)
          if (site !== -1) {
            val.forEach(el => {
              if (this.findInArr(el.username, this.selectUser) === -1) {
                this.selectUser.push(el)
              }
            })
          } else {
            // 未选中的值处理 取消左侧选中
            const site_c = this.findInArr(item.username, this.selectUser)
            if (site_c !== -1) {
              this.selectUser.splice(site_c, 1)
            }
          }
        } else {
          const site = this.findInArr(item.username, this.selectUser)
          if (site !== -1) {
            this.selectUser.splice(site, 1)
          }
        }
      })
    },
    findInArr(code, arr) {
      let find = -1
      arr.forEach((item, index) => {
        if (code === item.username) {
          find = index
        }
      })
      return find
    },
    handleClose(index, data) {
      // 判断右侧表格是否含有该数据，处理显示
      this.$nextTick(() => {
        this.checkedList.forEach(item => {
          if (data.username === item.username) {
            this.$refs.dataTable.toggleRowSelection(item, false)
          }
        })
      })
      this.selectUser.splice(index, 1)
    },
    nodeClick(data, node) {
      // console.log(8888222, data, node)
      if (data.disabled) {
        return
      }
      // 切换部门请求数据，table重置，不应触发change事件; 拿到数据后需要置为true（否则表格渲染完成后，首次点击选中无法触发change事件）
      this.needChange = false
      this.getDepartPerson(data.udId)
    },
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate)
      if (checked) {
        this.selectUser.push({ dpName: data.udName, departmentId: data.udId })
      } else {
        this.selectUser = this.selectUser.filter(item => data.udId !== item.departmentId)
      }
      // if (data.children && data.children.length > 0) {
      // }
    },
    handleCheck(a, b) {
      // console.log(888, a, b)
    },
    handleExpand(data, node, components) {
      console.log(data, node, components)
      if (node.checked || data.disabled) {
        node.expanded = false
      }
      if (data.children.length > 0 && this.disableDpIdList.length > 0) {
        data.children.forEach((item, index) => {
          if (this.disableDpIdList.includes(item.udId)) {
            item.disabled = true
            // node.childNodes[index].isLeaf = true
          }
        })
      }
    },
    closeTab() {
      this.$emit('update:visible', false)
    },
    save() {
      console.log(this.selectUser)
      this.$emit('save', this.selectUser)
      this.checkedList = []
    }
  }
}
</script>
<style scoped lang="scss">
.selected-main {
    .member-item {
      display: flex;
      align-items: center;
      height: 44px;
      padding-right: 16px;
      overflow: hidden;
      cursor: pointer;
    }
    .member-icon {
      width: 26px;
      height: 26px;
      font-size: 12px;
      line-height: 26px;
      text-align: center;
      display: inline-block;
      margin-right: 10px;
      border-radius: 50%;
      background: #f4870a;
      color: #fff;
    }
    .member-name {
      margin-right: 2px;
      flex: 1;
      color: #1f2329;
      white-space: nowrap;
      overflow: hidden;
    }
}
.right-dp-box {
  .table-content {
    height: 100%;
    overflow: auto;
  }
 /deep/.el-checkbox__input.is-disabled .el-checkbox__inner {
    background-color: #dbfcec;
  }
}
</style>
