export default {
  route: {
    index: 'Index'
  },
  navbar: {
    logOut: 'Log Out',
    index: 'Index'
  },
  login: {
    title: 'SFC',
    logIn: 'Log in',
    username: 'Username',
    password: 'Password'
  }
}
