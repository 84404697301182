import request from '@/utils/request'

// 我的空间 根据父节点查询子节点
export function selectChapterByParentId(params) {
  return request({
    url: '/chapterSpace/getChapterList',
    method: 'get',
    params: params
  })
}

// 我的空间 新增文章
export function addContentSpace(params) {
  return request({
    url: '/contentSpace/add',
    method: 'post',
    data: params
  })
}
// 我的空间 根据encryptionStr查询其ID，用来处理新增文件或文件夹时传父级ID
export function getForId(params) {
  return request({
    url: '/contentSpace/forId',
    method: 'get',
    params: params
  })
}
// 我的空间 新增文章 - new
export function addContentSpaceNew(params) {
  return request({
    url: '/contentSpace/addChapter',
    method: 'post',
    data: params
  })
}
// 我的空间 编辑文章
export function updateContentSpace(params) {
  return request({
    url: '/contentSpace/edit',
    method: 'post',
    data: params
  })
}
// 我的空间 新增文件夹
export function addChapterSpace(params) {
  return request({
    url: '/chapterSpace/addChapter',
    method: 'post',
    data: params
  })
}
// 我的空间 查询文章详情
export function selectArticleDetail(params) {
  return request({
    url: '/contentSpace/articleDetail',
    method: 'get',
    params: params
  })
}
// 查询节点详情，返回节点信息和父节点信息
export function chapterDetail(params) {
  return request({
    url: '/contentSpace/chapterDetail',
    method: 'get',
    params: params
  })
}
// 插入分享记录
export function recordShare(params) {
  return request({
    url: '/contentSpace/shareRecord',
    method: 'post',
    data: params
  })
}
// 查询dzz文件dpath
export function getDpath(params) {
  return request({
    url: '/contentSpace/chapterDpath',
    method: 'get',
    params: params
  })
}
// 发送钉钉通知
export function sendDDingMsg(params) {
  return request({
    url: '/contentSpace/notice',
    method: 'post',
    data: params
  })
}
// 回收站列表
export function selectRecycleList(params) {
  return request({
    url: '/chapterSpace/getChapterRecycle',
    method: 'get',
    params: params
  })
}
// 我的空间 软删
export function softDeleteSpace(params) {
  return request({
    url: '/chapterSpace/softDeleteChapter',
    method: 'post',
    data: params
  })
}
// 我的空间 硬删
export function deleteSpace(params) {
  return request({
    url: '/chapterSpace/deleteChapter',
    method: 'post',
    data: params
  })
}
// 回收站 恢复
export function recoverSpace(params) {
  return request({
    url: '/chapterSpace/recoverChapter',
    method: 'post',
    data: params
  })
}
// 我的空间 重命名
export function reNameSpace(params) {
  return request({
    url: '/chapterSpace/rename',
    method: 'post',
    data: params
  })
}
// 我的空间 获取父级树
export function getParentTreeList(params) {
  return request({
    url: '/chapterSpace/getParentTree',
    method: 'get',
    params: params
  })
}
// 我的空间 分享链接权限修改
export function updateChapterLink(params) {
  return request({
    url: '/chapterSpace/updateLinkPermission',
    method: 'post',
    data: params
  })
}

// 节点协作人权限配置
export function setPersonPower(params) {
  return request({
    url: '/permission/setPermission',
    method: 'post',
    data: params
  })
}
// 根据节点查询协作人
export function getPowerPerson(params) {
  return request({
    url: '/permission/getCollaboration',
    method: 'get',
    params: params
  })
}
// 移除节点权限
export function delPersonPower(params) {
  return request({
    url: '/permission/deleteCollaboration',
    method: 'post',
    data: params
  })
}
// 查询我协作的文章列表
export function getCooperationList(params) {
  return request({
    url: '/permission/getCooprationList',
    method: 'get',
    params: params
  })
}
// 查询此文章的操作权限
export function getHavePermission(params) {
  return request({
    url: '/permission/havePermission',
    method: 'get',
    params: params
  })
}
// 移动到空间-根据父节点查询子节点信息
export function getMoveSpaceList(params) {
  return request({
    url: '/chapterSpace/getChapterListByMove',
    method: 'get',
    params: params
  })
}
// 移动到空间指定文件夹下
export function moveToSpace(params) {
  return request({
    url: '/chapterSpace/moveToSpaceBatch',
    method: 'post',
    data: params
  })
}

// 分享弹窗查询知识库权限
export function getShareWikiPower(params) {
  return request({
    url: '/permission/wikiSharePermission',
    method: 'get',
    params
  })
}