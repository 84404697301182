import request from '@/utils/login-request'

export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

export function login(params) {
  return request({
    url: '/login',
    method: 'post',
    data: params
  })
}

export function getUserInfo() {
  return request({
    url: '/user/get/getuserAndAction',
    method: 'get'
  })
}

// 获取业务系统树形菜单
export function bussinessTree(reqParams) {
  return request({
    url: `/usermenu/get/bussinesstree/${reqParams}`,
    method: 'get'
    // params: reqParams
  })
}

// 获取用户列表
// export function getAllUsers() {
//   return request({
//     url: `/user/get/dingTaikUser/0`,
//     method: 'get'
//   })
// }
export function getAllUsers(params) {
  return request({
    url: '/user/get/list',
    method: 'get',
    params: params
  })
}

// 查询人员列表
// export function getUsers(params) {
//   return request({
//     url: '/user/get/list',
//     method: 'get',
//     params: params
//   })
// }
