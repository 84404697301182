<template>
  <div class="sidebar">
    <div class="sidebar-tab">
      <div class="logo">
        <div class="brand">
          <a href="/">
            <img src="../../../public/static/img/sfclogo.png" alt="logo" class="sfclogo">
          </a>
          <p class="logo-title" style="font-family:'FangSong';font-size:16px">三态云文档</p>
        </div>
      </div>
      <div class="sidebar-item">
        <tree-view :treeData="data"></tree-view>
      </div>
    </div>
    <div class="sidebar-slider"></div>
  </div>
</template>
<script>
import treeView from './treeView.vue'
import { deepClone } from '@/utils/index'

export default {
  components: { treeView },
  data() {
    return {
      data: [
        {
          id: -1,
          encryptionStr: '-1',
          hasArticle: 0,
          name: '主页',
          value: 'home',
          icon: 'home',
          specialIcon: '',
          active: false,
          router: '/drive/home'
        },
        {
          id: -1,
          encryptionStr: '-1',
          hasArticle: 0,
          name: '我的空间',
          value: 'my',
          icon: 'folder',
          specialIcon: 'add',
          active: false,
          router: '/drive/me',
          subTree: []
        },
        {
          id: -1,
          encryptionStr: '-1',
          hasArticle: 0,
          name: '与我分享',
          value: 'sharedme',
          icon: 'person',
          activeIcon: 'person_on',
          specialIcon: '',
          active: false,
          router: '/drive/shared',
          subTree: []
        },
        {
          id: -1,
          encryptionStr: '-1',
          hasArticle: 0,
          name: '与我协作',
          value: 'cooperation',
          icon: 'cooperation-icon',
          activeIcon: 'person_on',
          active: false,
          router: '/drive/cooperation',
          subTree: []
        },
        {
          id: -1,
          encryptionStr: '-1',
          hasArticle: 0,
          name: '知识库',
          value: 'knowledge',
          icon: 'knowledge',
          activeIcon: 'knowledge_on',
          active: false,
          router: '/wiki'
        },
        {
          id: -1,
          encryptionStr: '-1',
          hasArticle: 0,
          name: '收藏',
          value: 'collect',
          icon: 'collect',
          activeIcon: 'collect_on',
          active: false,
          router: '/drive/favorites'
        },
        {
          id: -1,
          encryptionStr: '-1',
          hasArticle: 0,
          name: '回收站',
          value: 'recycle',
          icon: 'recycle',
          activeIcon: 'recycle_on',
          active: false,
          router: '/drive/trash'
        }
      ]
    }
  },
  computed: {
    catalog() {
      return this.$store.getters.catalog
    }
  },
  watch: {
    catalog(val) {
      if (val) {
        this.handleData(val)
      }
    }
  },
  mounted() {
    console.log('fullpath', this.$route.fullPath)
    const site = ['/drive/home', '/drive/me', '/drive/shared', '/drive/cooperation', '/wiki', '/drive/favorites', '/drive/trash'].indexOf(this.$route.fullPath)
    if (site > -1) {
      this.data[site].active = true
    }
  },
  methods: {
    handleData(val) {
      const data = deepClone(this.data)
      function round(list) {
        list.forEach(el => {
          el.active = el.value === val
          if (el.subTree) {
            round(el.subTree)
          }
        })
      }
      round(data)
      this.$nextTick(() => {
        this.data = data
      })
      console.log('最终数据', data)
    }
  }
}
</script>
<style lang="scss" scoped>
.sidebar {
  width: 260px;
  z-index: 99;
  overflow: hidden;
  height: 100%;
  background: #f5f6f7;
  display: block;
  .sidebar-tab {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    height: 100%;
    .logo {
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      flex-direction: column;
      padding-top: 16px;
      padding-bottom: 30px;
      height: 88px;
      width: 100%;
      flex-shrink: 0;
      .brand {
        margin: 0 12px;
        padding: 5px 8px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        overflow: hidden;
        .sfclogo {
          // width: 100px;
          height: 40px;
        }
        .logo-title {
          font-size: 14px;
          line-height: 40px;
          font-weight: 700;
          color: #1f2329;
          margin: 0 0 0 5px;
        }
      }
    }
    .sidebar-item {
      overflow: overlay;
      box-shadow: inset 0px -15px 6px -15px rgba(0,0,0,0.15);
      -webkit-box-flex: 1;
      flex-grow: 1;
    }
  }
  .sidebar-slider {
    position: absolute;
    top: 0;
    left: 260px;
    height: 100%;
    width: 6px;
    z-index: 90;
    transform: translateX(-50%);
  }
}
</style>
