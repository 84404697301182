<template>
  <el-dialog title="编辑个人信息" :visible.sync="visibleDialog">
    <el-card class="personalPanel">
      <el-divider content-position="left">个人信息</el-divider>
      <div class="content clearfix">
        <div class="operatePanel">
          <el-button type="text" v-if="!edit" @click="editMsg"><i class="el-icon-edit"></i></el-button>
          <el-button type="text" v-if="edit" @click="save"><i class="el-icon-check"></i></el-button>
          <el-button type="text" v-if="edit" @click="cancel"><i class="el-icon-close"></i></el-button>
        </div>
        <div class="photo" :class="{'photoEdit': edit}" :title="edit ? '更换头像' : ''" @click="editImg">
          <img v-if="headImageUrl" :src="headImageUrl" alt="">
          <!-- <img v-else src="../../images/photo.jpg" alt=""> -->
        </div>
        <div class="message">
          <ul class="basicMessage clearfix">
            <li class="small clearfix">
              <span class="key">姓名：</span>
              <el-input class="noborder" v-model="messageInfo.username" :disabled="true" size="mini"></el-input>
            </li>
            <li class="small clearfix">
              <span class="key">工号：</span>
              <el-input class="noborder" v-model="messageInfo.workcode" :disabled="true" size="mini"></el-input>
            </li>
            <li class="small clearfix">
              <span class="key">性别：</span>
              <el-radio v-model="messageInfo.sex" :label="0" :disabled="!edit">男</el-radio>
              <el-radio v-model="messageInfo.sex" :label="1" :disabled="!edit">女</el-radio>
            </li>
            <li class="small clearfix">
              <span class="key">年龄：</span>
              <el-input class="noborder" v-model="messageInfo.age" :disabled="!edit" size="mini"></el-input>
            </li>
            <li class="clearfix">
              <span class="key floatL">简介：</span>
              <el-input class="floatL" type="textarea" rows="4" style="width: calc(100% - 50px)" :disabled="!edit" v-model="messageInfo.introduce" maxlength="120" show-word-limit></el-input>
            </li>
          </ul>
        </div>
      </div>
    </el-card>
    <photo-edit :visible.sync="visible" @save="changeImg"></photo-edit>
  </el-dialog>
</template>
<script>
import photoEdit from './photo.vue'
import { getUser, updateUserInfo } from '@/api/user'
export default {
  components: { photoEdit },
  data() {
    return {
      edit: false,
      messageInfo: {
        username: '',
        workcode: '',
        sex: null,
        age: null,
        introduce: ''
      },
      articleList: [],
      visible: false,
      oldInfo: {},
      visibleDialog: false
    }
  },
  computed: {
    headImageUrl() {
      return this.$store.getters.headImageUrl ? `${process.env.VUE_APP_BASE_API}/user/downloadHeadImg?fileName=` + this.$store.getters.headImageUrl + `&${this.code}` : null
    },
    code() {
      const urlInfo = JSON.parse(sessionStorage.getItem('urlInfo'))
      const code = 'token=' + urlInfo.token + '&hostIp=' + urlInfo.ip + '&systemCode=' + urlInfo.systemCode + '&sfcLang=en'
      return code
    }
  },
  mounted() {
    this.getMessageInfo()
  },
  methods: {
    openDialog() {
      this.visibleDialog = true
    },
    getMessageInfo() {
      getUser({ workcode: this.$store.getters.workcode }).then(res => {
        if (res.data.length > 0) {
          this.messageInfo = JSON.parse(JSON.stringify(res.data[0]))
          this.$store.commit('SET_HEADiMAGE', this.messageInfo.headImgName)
        }
        this.messageInfo.workcode = this.$store.getters.workcode
        this.messageInfo.username = this.$store.getters.name
        this.oldInfo = JSON.parse(JSON.stringify(this.messageInfo))
      }).catch(() => {

      })
    },
    editMsg() {
      this.edit = true
    },
    save() {
      this.$confirm('确定修改信息？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.edit = false
        const params = JSON.parse(JSON.stringify(this.messageInfo))
        params.age = parseInt(params.age)
        updateUserInfo(params).then(res => {
          if (res.code === '000000') {
            this.$message({
              message: res.message,
              type: 'success'
            })
            this.visibleDialog = false
          }
        }).catch(() => {
          this.messageInfo = JSON.parse(JSON.stringify(this.oldInfo))
        })
      }).catch(() => {
        this.$message.info('已取消！')
        this.messageInfo = JSON.parse(JSON.stringify(this.oldInfo))
      })
    },
    cancel() {
      // 刷新数据
      this.edit = false
      this.messageInfo = JSON.parse(JSON.stringify(this.oldInfo))
    },
    editImg() {
      if (this.edit) {
        this.visible = true
      }
    },
    changeImg() {
      this.visible = false
    }
  }
}
</script>
<style lang="scss" scoped>
.personalPanel {
  width: 800px;
  min-height: 515px;
  padding: 0 30px;
  margin: 50px auto;
  .el-divider--horizontal {
    margin: 14px 0 !important;
  }
  .el-divider__text {
    color: #999;
  }
  .content {
    height: 255px;
    padding: 0 20px 10px;
    .operatePanel {
      width: 100%;
      height: 30px;
      text-align: right;
      button {
        padding: 0;
        span i {
          font-size: 18px;
        }
      }
    }
    .photo {
      width: 220px;
      height: 220px;
      border: 1px solid #DCDFE6;
      padding: 8px;
      float: left;
      img {
        width: 200px;
        height: 200px;
      }
    }
    .photoEdit {
      border: 1px dashed #409EFF;
    }
    .message {
      float: right;
      width: 380px;
      height: 180px;
    }
  }
}
.message .basicMessage{
  padding: 0 10px;
  li {
    line-height: 40px;
    float: left;
    width: 100%;
    .key {
      width: 50px;
      float: left;
    }
    .value {
      float: left;
      width: calc(100% - 50px);
    }
    .el-input__count {
      line-height: 20px;
    }
  }
  li.small {
    width: 50%;
    .noborder {
      width: calc(100% - 60px);
      .el-input__inner {
        height: 24px;
        line-height: 24px;
        border: 0 none;
        border-bottom: 1px solid #ccc;
      }
    }
  }
}
</style>
