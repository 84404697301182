import Vue from 'vue'
import Router from 'vue-router'

/* Layout */
import Layout from '@/views/layout/Layout'
Vue.use(Router)

/**
* hidden: true                   如果 `hidden:true` 则不会在侧边栏sidebar中显示 (default is false)
* alwaysShow: true               如果设置这个为true 则始终显示根菜单，无论它子路由的长度是多少都不显示
*                                如果没设置alwaysShow这个属性，则子级下只能有一个以上的路由，它就会成为嵌套模式，不然就不显示根菜单

* redirect: noredirect           如果这样设置 `redirect:noredirect` 面包屑breadcrumb中不会重定向
* name:'router-name'             name这个属性由 <keep-alive> 使用  (必须设置！！！)
* meta : {
    title: 'title'               子菜单和面包屑中显示的名称
    icon: 'svg-name'             图标显示在侧边栏sidebar中
    breadcrumb: false            如果为false，则该项将隐藏在breadcrumb中(default is true)
  }
**/
export const constantRouterMap = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/testAomao',
    component: () => import('@/components/AomaoEditor/Editor.vue'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/errorPage/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/errorPage/401'),
    hidden: true
  },
  // {
  //   path: '/aa',
  //   component: () => import('@/views/aa/index'),
  //   hidden: true
  // },
  {
    path: '',
    component: Layout,
    redirect: '/drive/home',
    children: [
      {
        path: '/drive/home',
        component: () => import('@/views/index/index')
        // name: 'home',
        // nameZh: '首页',
        // meta: { title: '/drive/home', icon: 'dashboard' }
      },
      {
        path: '/drive/me',
        component: () => import('@/views/me/index')
      },
      {
        path: '/drive/folder',
        component: () => import('@/views/folder/index')
      },
      {
        path: '/drive/folder/*',
        component: () => import('@/views/folder/index')
      },
      {
        path: '/drive/shared',
        component: () => import('@/views/shared/index')
      },
      {
        path: '/drive/share/folder/*',
        component: () => import('@/views/folder/index')
      },
      {
        path: '/drive/cooperation',
        component: () => import('@/views/cooperation/index')
      },
      {
        path: '/drive/cooperation/folder/*',
        component: () => import('@/views/folder/index')
      },
      {
        path: '/drive/favorites',
        component: () => import('@/views/favorites/index')
      },
      {
        path: '/drive/favorites/folder/*',
        component: () => import('@/views/folder/index')
      },
      {
        path: '/wiki',
        component: () => import('@/views/wiki/index')
      },
      {
        path: '/drive/trash',
        component: () => import('@/views/trash/index')
      },
      {
        path: '/drive/trash/*',
        component: () => import('@/views/trash/index')
      },
      {
        path: '/drive/aa',
        component: () => import('@/views/aa/index')
      }
    ]
  },
  {
    path: '/wiki/article/*',
    component: () => import('@/views/article/index'),
    hidden: true
  },
  {
    path: '/wiki/detail/*',
    component: () => import('@/views/wiki/detail'),
    hidden: true
  },
  {
    path: '/wiki/transfer/*', // dzz分享记录中转页
    component: () => import('@/views/transfer/index')
  },
  {
    path: '/wiki/player', // 视频播放页
    component: () => import('@/views/player/index')
  }
]

const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})

export const asyncRouterMap = [
  { path: '*', redirect: '/404', hidden: true }
]

export const routerMap = {
  // 测试页面
  // account: (resolve) => require(['@/views/test/index'], resolve)
}
