<template>
  <div v-if="visible">
    <el-dialog
      :visible="true"
      width="500px"
      :title="file_type ? '新建文件' : '新建文件夹'"
      v-ismove
      :append-to-body="true"
      :close-on-click-modal="false"
      :before-close="closeTab">
      <div style="min-height:200px;padding-bottom:40px;position:relative">
        <el-input :placeholder="file_type ? '请输入文件名称' : '请输入文件夹名称'" v-model="folderName" clearable size="small" style="margin-bottom: 10px">
        </el-input>
        <div style="text-align:right;position:absolute;bottom:0px;width:100%">
          <el-button size="small" @click="closeTab">取消</el-button>
          <el-button v-if="file_type" v-loading="isAddLoading" size="small" type="primary" :disabled="!folderName" @click="submitAddPhp">确定</el-button>
          <el-button v-else v-loading="isAddLoading" size="small" type="primary" :disabled="!folderName" @click="submitAdd">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { addChapterSpace } from '@/api/space'
import { newFile, uploadChapter } from '@/api/fileApi'
// import { viewLatelyList } from '@/api/template'

export default {
  data() {
    return {
      folderName: '',
      isAddLoading: false
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    encryptionStr: {
      type: String,
      default: null
    },
    file_type: {
      type: String,
      default: null
    }
  },
  methods: {
    closeTab() {
      this.folderName = ''
      this.$emit('closeTab')
    },
    submitAdd() {
      this.isAddLoading = true
      const params = {
        encryptionStr: this.encryptionStr,
        title: this.folderName,
        sortNo: 1,
        username: this.$store.getters.name
      }
      addChapterSpace(params).then((res) => {
        this.isAddLoading = false
        if (res.code === '000000') {
          this.$message({
            message: res.message,
            type: 'success',
            duration: 1400
          })
          this.folderName = ''
          this.$emit('addFolderSuccess')
          // 新建成功后存入首页最近修改
          // const params2 = {
          //   chapterId: item.id,
          //   type: item.type,
          //   username: item.username,
          //   workcode: item.workcode
          // }
          // viewLatelyList(params2).then(res => {
          //   console.log(res)
          // }).catch(error => console.log(error))
        } else {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 1400
          })
        }
      }).catch(err => {
        this.isAddLoading = false
        console.log(err)
      })
    },
    submitAddPhp() {
      this.isAddLoading = true
      const Base64 = require('js-base64').Base64
      const urlCode = Base64.encode(sessionStorage.getItem('urlInfo'))
      newFile({
        action: 'createFile',
        file_type: this.file_type,
        code: urlCode
      }).then((res) => {
        const addParams = {
          encryptionStr: this.encryptionStr,
          title: this.folderName,
          sortNo: 1,
          username: this.$store.getters.name,
          type: this.file_type,
          dpath: res.data.dpath
        }
        uploadChapter(addParams).then((response) => {
          this.isAddLoading = false
          this.folderName = ''
          this.$emit('addFolderSuccess')
          const Base64 = require('js-base64').Base64
          const urlCode = Base64.encode(sessionStorage.getItem('urlInfo'))
          const href = `${process.env.VUE_APP_PHP_BASE_API}/api.php?action=onlyOfficeEdit&path=` + res.data.dpath + `&code=${urlCode}`
          window.open(href, '_blank')
        }).catch(err => {
          this.isAddLoading = false
          // 还需删除上传的文件
          console.log(err)
        })
      }).catch(err => {
        this.isAddLoading = false
        console.log(err)
      })
    }
  }
}
</script>
