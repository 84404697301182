<template>
  <div v-if="addPowerVisible">
    <el-dialog
      :visible="true"
      width="500px"
      title="邀请协作者"
      v-ismove
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="false"
      :before-close="closeTab">
      <!-- <div> -->
        <div style="display: flex;">
          <el-select style="flex: auto;" size="small" v-model="permission" placeholder="请选择">
            <el-option label="可管理" :value="1" :disabled="listPermission!==1"></el-option>
            <el-option label="可编辑" :value="2" :disabled="listPermission===3"></el-option>
            <el-option label="可阅读" :value="3"></el-option>
          </el-select>
          <el-button size="small" @click="selectUser">
            <i class="el-icon-plus"></i>选择人员
          </el-button>
        </div>
        <!-- <el-button style="width: 100%;" size="small" @click="selectUser">
          <i class="el-icon-plus"></i>选择人员
        </el-button> -->
        <div class="tag-box">
          <el-tag size="small" v-for="item in userList" :key="item.username" effect="plain">
            {{ item.username ? item.name + '(' + item.username + ')' : item.dpName + '(' + item.departmentId + ')' }}
          </el-tag>
        </div>
      <!-- <span>
        <el-button class="delete_text" type="text" size="mini" @click="userList = []">
          清空
        </el-button>
      </span> -->
      <div slot="footer">
        <el-button size="small" @click="closeTab">取消</el-button>
        <el-button v-loading="isLoading" size="small" type="primary" @click="submitPermission">确定</el-button>
      </div>
      <!-- 人员选择组件 -->
      <user-conf v-bind.sync="selectObj" :permissionUserList="permissionUserList" :createUser="createUser" @save="saveUsre"></user-conf>
    </el-dialog>
  </div>
</template>
<script>
import { setPersonPower } from '@/api/space'
import userConf from '@/components/DepartmentPerson'
import { deepClone } from '@/utils/index'

export default {
  components: { userConf },
  data() {
    return {
      isLoading: false,
      selectObj: {
        visible: false,
        allUser: []
      },
      userList: [],
      selectDp: [],
      permission: 3
    }
  },
  props: {
    addPowerVisible: {
      type: Boolean,
      default: false
    },
    permissionUserList: {
      type: Array,
      default: () => []
    },
    chapterId: {
      type: Number,
      default: null
    },
    createUser: {
      type: String,
      default: ''
    },
    listPermission: {
      type: Number,
      default: 3
    }
  },
  watch: {
    addPowerVisible(val) {
      if (val) {
        // this.userList = JSON.parse(JSON.stringify(this.permissionUserList))
        // this.userList.forEach(item => {
        //   item.name = item.username
        //   item.username = item.workcode
        // })
      }
    }
  },
  methods: {
    closeTab() {
      this.userList = []
      this.$emit('closeAddPowerDialog')
    },
    selectUser() {
      this.selectObj = {
        visible: true,
        hasChoosed: this.userList // 已选择的
      }
    },
    saveUsre(selectUser, dpList) {
      this.selectObj.visible = false
      this.userList = selectUser ? deepClone(selectUser) : []
      this.selectDp = dpList ? deepClone(dpList) : []
      console.log(9999, this.userList)
    },
    submitPermission() {
      const params = {
        userList: [],
        dpList: [],
        chapterId: this.chapterId,
        permission: this.permission
      }
      this.userList.forEach(item => {
        if (item.username) {
          params.userList.push({ workcode: item.username, username: item.name })
        } else {
          params.dpList.push({ departmentId: item.departmentId, dpName: item.dpName })
        }
      })
      setPersonPower(params).then((res) => {
        if (res.code === '000000') {
          this.$message({
            message: res.message,
            type: 'success',
            duration: 1400
          })
          this.userList = []
          this.$emit('addSuccess')
        } else {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 1400
          })
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
<style scoped lang="scss">
.el-tag + .el-tag {
  margin-left: 10px;
}
.delete_text {
  color: #F56C6C;
}
.title-text {
  font-size: 12px;
  line-height: 30px;
  padding-top: 10px;
  color: #6d7175;
}
.tag-box {
  padding-top: 10px;
  min-height: 80px;
}
</style>
