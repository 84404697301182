<template>
  <div v-if="dialogVisible">
    <el-dialog
      :visible="true"
      width="500px"
      title="链接分享"
      v-ismove
      :append-to-body="true"
      :show-close="false"
      :before-close="closeTab">
      <template slot="title">
        <div class="dialog-title-box">
          <span>邀请协作者</span>
          <div class="person-box">
            <div class="name-item">
              <span style="background: #00d3ba" class="users">{{ chapterObj.username }}</span>
            </div>
            <template v-if="permissionUserList.length > 0">
              <div class="name-item" v-for="(item, index) in permissionUserList.slice(0, 2)" :key="index">
            <span class="users" v-if="item.username">{{ item.username }}</span>
            <span class="users" v-else style="background:#3370ff">{{ item.dpName }}</span>
              </div>
            </template>
            <i class="el-icon-caret-right" @click="openPowerConf"></i>
          </div>
        </div>
      </template>
      <div>
        <el-button style="width: 100%;" size="small"
        :disabled="toAllDisabled"
         @click="openAddPowerConf">
          <i class="el-icon-plus"></i>添加协作人
        </el-button>
      </div>
      <div class="title-text">链接分享权限限制</div>
      <div>
        <el-switch
          v-model="chapterObj.linkToAll"
          :disabled="toAllDisabled"
          @change="updateIsToAll"
          active-color="#3370ff"
          inactive-color="#d0d3d6"
          :active-value="1"
          :inactive-value="0">
        </el-switch>
        <span class="tip-text">{{ chapterObj.linkToAll === 1 ? '已开启，所有人都可通过链接查看' : '未开启，只有协作人可通过链接打开此内容' }}</span>
      </div>
      <div class="link-permission-box">
        <el-select class="write-select-input" :disabled="toAllDisabled" size="small" v-model="chapterObj.linkWrite" @change="updateIsToAll" placeholder="请选择">
          <el-option label="获得链接的人可阅读" :value="0"></el-option>
          <el-option label="获得链接的人可编辑" :value="1"></el-option>
        </el-select>
        <!-- <span class="operate" v-clipboard:copy="copyPathLink">复制链接</span> -->
        <el-button class="write-append-text" size="small" v-clipboard:copy="copyPathLink" @click="copyLink">复制链接</el-button>
      </div>
      <!-- 添加协作人 -->
      <add-power-dialog :addPowerVisible="addPowerVisible" :chapterId="chapterObj.id" :listPermission="listPermission" :createUser="chapterObj.workcode" :permissionUserList="permissionUserList"
        @closeAddPowerDialog="closeAddPowerDialog"
        @addSuccess="addSuccess">
      </add-power-dialog>
      <!-- 协作人权限配置 -->
      <conf-power-dialog :powerVisible="powerVisible" :listPermission="listPermission" :chapterId="chapterObj.id" :permissionUserList="permissionUserList"
        @closePowerDialog="closePowerDialog"
        @deleteSuccess="deleteSuccess"
        @confSuccess="confSuccess">
      </conf-power-dialog>
    </el-dialog>
  </div>
</template>
<script>
import { getPowerPerson, updateChapterLink, getShareWikiPower } from '@/api/space'
import addPowerDialog from './components/addUserDialog.vue'
import confPowerDialog from './components/userPowerConf.vue'
// import { deepClone } from '@/utils/index'

export default {
  components: {
    addPowerDialog,
    confPowerDialog
  },
  data() {
    return {
      isLoading: false,
      powerVisible: false,
      addPowerVisible: false,
      permissionUserList: [],
      listPermission: 0
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    chapterObj: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        // 获取协作人列表
        this.getCollaboration()
      }
    }
  },
  computed: {
    isDisabled() {
      // 作者/管理、编辑、阅读权限，对应的人只能分《=自己的权限  知识库有编辑权限2一二级管理员1
      return this.listPermission===0
    },
    toAllDisabled() {
      // 可管理或者作者才可修改文章本身的读写权限  知识库一二级管理员1
      return this.listPermission !== 1
    },
    copyPathLink() {
      let router = window.location.origin
      // dzz的文档
      if (this.chapterObj.dpath) {
        router += `/wiki/transfer/${this.chapterObj.encryptionStr}`
      } else if (window.location.pathname.indexOf('/wiki/detail/') > -1 || this.chapterObj.origin === 2) {
        // 知识库文章
        router += `/wiki/detail/${this.chapterObj.encryptionStr}`
      } else {
        router += this.chapterObj.hasArticle === 1 ? `/wiki/article/${this.chapterObj.encryptionStr}` : `/drive/share/folder/${this.chapterObj.encryptionStr}`
      }
      return router
    }
  },
  methods: {
    closeTab() {
      // this.folderName = ''
      this.$emit('closeShareDialog')
    },
    getWikiPermission() {
      // 获取知识库权限
      getShareWikiPower({ 
        kbId: this.chapterObj.kbId,
        dpIdStr: this.$store.getters.udId.replaceAll('-', ',')
      }).then((res) => {
        this.isLoading = false
        if (res.code === '000000') {
          if (res.data.permission !== 0) {
            // 取知识库的权限和文章协作人权限并集
            if (this.listPermission === 0 || res.data.permission < this.listPermission) {
              this.listPermission = res.data.permission
            }
          }
          console.log('权限-最后', this.listPermission)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getCollaboration() {
      if (this.chapterObj.workcode === JSON.parse(sessionStorage.getItem('urlInfo')).username) {
        this.listPermission = 1
      } 
      this.isLoading = true
      getPowerPerson({ id: this.chapterObj.id }).then((res) => {
        if (res.code === '000000') {
          this.permissionUserList = res.data
          // 列表点击分享  列表无单独对应的权限
          const tempIndex = 0
          for (let index = 0; index < this.permissionUserList.length; index++) {
            const element = this.permissionUserList[index]
            if (element.workcode === JSON.parse(sessionStorage.getItem('urlInfo')).username) {
              this.listPermission = element.permission
              break
            } else {
              const dpIdList = this.$store.getters.udId.split('-')
              const dpIndex = dpIdList.findIndex(dpId => Number(dpId) === element.departmentId)
              if (dpIndex >= tempIndex) {
                this.listPermission = element.permission
              }
            }
          }
          console.log('权限-文章', this.listPermission)
          if (this.chapterObj.origin === 2) {
            // 知识库文章
            this.getWikiPermission()
          } else {
            this.isLoading = false
          }
        }
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    updateIsToAll(val) {
      updateChapterLink({
        encryptionStr: this.chapterObj.encryptionStr,
        linkToAll: this.chapterObj.linkToAll,
        linkWrite: this.chapterObj.linkWrite
      }).then((res) => {
        this.$message.success('修改成功')
        this.getListData()
      }).catch(err => {
        console.log(err)
      })
    },
    getListData() {
      this.$emit('powerSuccess')
    },
    copyLink() {
      this.$message.success('复制成功')
    },
    openAddPowerConf() {
      this.addPowerVisible = true
    },
    closeAddPowerDialog() {
      this.addPowerVisible = false
    },
    addSuccess() {
      this.addPowerVisible = false
      this.getCollaboration()
      // this.permissionUserList = deepClone(this.userList)
    },
    openPowerConf() {
      this.powerVisible = true
    },
    closePowerDialog() {
      this.powerVisible = false
    },
    deleteSuccess(index) {
      this.permissionUserList.splice(index, 1)
    },
    confSuccess(permission, index) {
      this.permissionUserList[index].permission = permission
      // this.powerVisible = false
      // this.getCollaboration()
      // this.permissionUserList = deepClone(this.userList)
    }
  }
}
</script>
<style scoped lang="scss">
.title-text {
  font-size: 12px;
  line-height: 30px;
  padding-top: 10px;
  color: #6d7175;
}
.tip-text {
  padding-left: 10px;
  font-size: 12px;
  line-height: 40px;
  color: #646a73;
}
.dialog-title-box {
  display: flex;
  justify-content: space-between;
  .person-box {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    .name-item {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .users {
        width: 26px;
        height: 26px;
        font-size: 12px;
        line-height: 26px;
        text-align: center;
        display: inline-block;
        margin-left: 10px;
        border-radius: 50%;
        background: #f4870a;
        color: #fff;
      }
    }
  }
}
.link-permission-box {
    display: flex;
  .write-select-input {
    flex: auto;
  }
  .write-append-text {
  }
}
</style>
