import Cookies from 'js-cookie'

const app = {
  state: {
    device: 'desktop',
    language: Cookies.get('language') || 'zh',
    size: Cookies.get('size') || 'medium',
    catalog: true, // 监听目录点击更新
    catalogTree: [], // 监听目录点击保存的上级节点名称
    mdStr: '', // md内容
    mdChange: 0, // 评论变动更新md文本，不触发请求
    // needChange: 0, // 编辑模式修改评论，必需触发更显文章内容
    commentChange: 0, // 编辑文章删除评论后更新评论列表
    delComments: [], // 编辑文章删除评论，需在评论组件中删除，以触发位置更新
    catalogWiki: '', // 监听知识库目录点击更新
    wikiMenuItem: {
      id: '',
      type: '',
      dpath: ''
    } // 知识库点击DZZ文件的菜单信息
  },
  mutations: {
    TOGGLE_DEVICE: (state, device) => {
      state.device = device
    },
    SET_LANGUAGE: (state, language) => {
      state.language = language
      Cookies.set('language', language)
    },
    SET_SIZE: (state, size) => {
      state.size = size
      Cookies.set('size', size)
    },
    SET_CATALOG: (state, val) => {
      state.catalog = val
    },
    SET_CATALOG_TREE: (state, val) => {
      state.catalogTree = val
    },
    SET_CATALOG_WIKI: (state, val) => {
      state.catalogWiki = val
    },
    SET_WIKI_MENU_ITEM: (state, val) => {
      state.wikiMenuItem = val
    },
    SET_MD: (state, mdStr) => {
      state.mdStr = mdStr
      state.mdChange++
    },
    // NEED_CHANGE_MD: (state) => {
    //   state.needChange++
    // },
    UPDATE_COMMENT: (state) => {
      state.commentChange++
    },
    DEL_COMMENTS: (state, delArr) => {
      state.delComments = delArr
    }
  },
  actions: {
    delComment({ commit }, delArr) {
      commit('DEL_COMMENTS', delArr)
    },
    setMd({ commit }, mdStr) {
      commit('SET_MD', mdStr)
    },
    // needChangeMd({ commit }) {
    //   commit('NEED_CHANGE_MD')
    // },
    updateComment({ commit }) {
      commit('UPDATE_COMMENT')
    },
    toggleDevice({ commit }, device) {
      commit('TOGGLE_DEVICE', device)
    },
    setLanguage({ commit }, language) {
      commit('SET_LANGUAGE', language)
    },
    setSize({ commit }, size) {
      commit('SET_SIZE', size)
    },
    toggleCatalog({ commit }, val) {
      commit('SET_CATALOG', val)
    },
    setCatalogTree({ commit }, val) {
      commit('SET_CATALOG_TREE', val)
    },
    toggleCatalogWiki({ commit }, val) {
      commit('SET_CATALOG_WIKI', val)
    },
    setWikiMenuItem({ commit }, val) {
      commit('SET_WIKI_MENU_ITEM', val)
    }
  }
}

export default app
