<template>
  <div class="upload_main" v-if="fileList.length > 0">
    <div class="top_box">
      <span class="top_title">上传完成[{{ fileList.filter(item => item.upLoadProgress === 100).length }}/{{ fileList.length }}]</span>
      <div class="top_btn">
        <i :class="isLessen ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" @click="packUpClick"></i>
        <!-- <i v-else class="el-icon-arrow-up"></i> -->
        <i class="el-icon-close" style="margin-left: 14px" @click="closePage"></i>
      </div>
    </div>
    <div v-show="!isLessen" class="tip_text" >刷新页面、关闭浏览器和关闭小窗口都会终止正在上传的文件！！！</div>
    <div v-show="!isLessen" class="upload_item" v-for="(item, index) in fileList" :key="index">
      <svg-icon v-if="renderFileType(item.name, ['xls', 'xlsx'])" class="file_icon" icon-class="excel_icon"></svg-icon>
      <svg-icon v-else-if="renderFileType(item.name, ['ppt', 'pptx'])" class="file_icon" icon-class="ppt"></svg-icon>
      <svg-icon v-else-if="renderFileType(item.name, ['mp4', 'm2v', 'mkv', 'rmvb', 'wmv', 'avi', 'flv', 'mov', 'm4v'])" class="file_icon" icon-class="mp4"></svg-icon>
      <svg-icon v-else-if="renderFileType(item.name, ['doc', 'docx'])" class="file_icon" icon-class="word"></svg-icon>
      <svg-icon v-else icon-class="document"></svg-icon>
      <div class="upload_detail">
        <div class="item_name">{{ item.name }}</div>
        <div class="item_tip">
          size: {{ renderSize(item.size) }}
          <span style="margin-left: 12px;">{{ handleTime(item.uplaodTime, "HH:mm:ss") }}</span></div>
      </div>
      <i v-if="item.isError" class="el-icon-circle-close" style="font-size: 30px;color: #F56C6C"></i>
      <i v-else-if="item.upLoadProgress === 100" class="el-icon-circle-check" style="font-size: 30px;color: #67C23A"></i>
      <el-progress v-else class="progress_icon" type="circle" :width="34" :percentage="item.upLoadProgress"></el-progress>
      <!-- <el-progress :text-inside="true" :stroke-width="14" :percentage="upLoadProgress"></el-progress> -->
    </div>
  </div>
</template>
<script>
import { uploadFile, uploadChapterBatch } from '@/api/fileApi'

export default {
  data() {
    return {
      isLessen: false
      // fileList: []
    }
  },
  computed: {
    uploadFileTemp() {
      return this.$store.getters.uploadFileList.length
    },
    fileList() {
      return this.$store.getters.uploadFileList
    }
  },
  watch: {
    uploadFileTemp: {
      handler(val, oldVal) {
        console.log(val, oldVal)
        if (val > 0) {
          this.fileList.forEach((item, index) => {
            if (index >= oldVal && index <= val) {
              this.submitUpload(index)
            }
          })
          // this.fileList.push(Object.assign({}, val, {
          //   upLoadProgress: 0,
          //   isError: false
          // }))
          // this.submitUpload(this.fileList.length - 1)
        }
      },
      deep: true
    }
  },
  methods: {
    closePage() {
      if (this.fileList.filter(item => item.upLoadProgress < 100).length > 0) {
        this.$confirm('是否要关闭此窗口，将终止正在上传的文件?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // this.fileList = []
          this.$store.dispatch('updateUploadList', [])
        })
          .catch((err) => {
            console.log(err)
            this.$message({
              type: 'info',
              message: '已取消'
            })
          })
      } else {
        // this.fileList = []
        this.$store.dispatch('updateUploadList', [])
      }
    },
    packUpClick() {
      this.isLessen = !this.isLessen
    },
    renderSize(filesize) {
      if (filesize === null || filesize === '') {
        return '0 Bytes'
      }
      var unitArr = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      var index = 0
      var srcsize = parseFloat(filesize)
      index = Math.floor(Math.log(srcsize) / Math.log(1024))
      var size = srcsize / Math.pow(1024, index)
      size = size.toFixed(2)// 保留的小数位数
      return size + unitArr[index]
    },
    renderFileType(fileName, typeArr) {
      // 获取最后一个.的位置
      var index = fileName.lastIndexOf('.')
      // 获取后缀
      var ext = fileName.substr(index + 1)
      return typeArr.includes(ext.toLowerCase())
    }, // 定义格式化函数：
    handleTime(time, format) {
      if (time === null || time === undefined || time === '') {
        return ''
      }
      var t = new Date(time)
      var tf = function(i) {
        return (i < 10 ? '0' : '') + i
      }
      return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function(a) {
        let tTemp = null
        switch (a) {
          case 'yyyy':
            tTemp = tf(t.getFullYear())
            break
          case 'MM':
            tTemp = tf(t.getMonth() + 1)
            break
          case 'mm':
            tTemp = tf(t.getMinutes())
            break
          case 'dd':
            tTemp = tf(t.getDate())
            break
          case 'HH':
            tTemp = tf(t.getHours())
            break
          case 'ss':
            tTemp = tf(t.getSeconds())
            break
        }
        return tTemp
      })
    },
    async submitUpload(index) {
      this.fileList[index].upLoadProgress = 0
      // 每个文件切片大小定为10MB
      var sliceSize = 10 * 1024 * 1024
      const that = this
      const blob = this.fileList[index].raw
      const fileSize = blob.size// 文件大小
      // 计算文件切片总数
      const totalSlice = Math.ceil(fileSize / sliceSize)// 循环上传
      console.log(fileSize, totalSlice)
      for (let i = 0; i < totalSlice; i++) {
        const Base64 = require('js-base64').Base64
        const urlCode = Base64.encode(sessionStorage.getItem('urlInfo'))
        const formData = new FormData()
        const start = i * sliceSize
        const chunkFile = blob.slice(start, Math.min(fileSize, start + sliceSize))
        formData.append('files[]', chunkFile)
        formData.append('signal', blob.uid)
        formData.append('name', blob.name)
        formData.append('size', fileSize)
        // 一个分片上传完成后再调用接口上传下一片
        let isError = false
        console.log(i)
        await uploadFile({
          action: 'uploadFiles',
          code: urlCode,
          chunks_count: totalSlice,
          chunk: i + 1
        }, formData).then(res => {
          that.fileList[index].upLoadProgress = Math.floor((i / totalSlice).toFixed(2) * 100)// 控制进度条
          if ((i + 1) === totalSlice) {
            that.fileList[index].upLoadProgress = 100
            that.addToChapter(res, index)
          }
        }).catch(err => {
          console.log('上传失败', err)
          isError = true
          this.fileList[index].isError = true
        })
        if (isError) {
          break
        }
      }
    },
    addToChapter(res, fileIndex) {
      const arrType = []
      const dpathArr = []
      const titleArr = []
      res.data.files.forEach((item, index) => {
        dpathArr.push(item.data.icoarr[0].dpath)
        titleArr.push(this.fileList[fileIndex].name)
        const flieArr = this.fileList[fileIndex].name.split('.') // 根据.分割数组
        let suffix = flieArr[flieArr.length - 1] // 取最后一个
        if (!suffix) return false // fileName无后缀返回false
        suffix = suffix.toLocaleLowerCase() // 将后缀所有字母改为小写方便操作
        if (['doc', 'docx'].includes(suffix)) {
          arrType.push('newDoc')
        } else if (['xls', 'xlsx'].includes(suffix)) {
          arrType.push('newExcel')
        } else if (['mp4', 'm2v', 'mkv', 'rmvb', 'wmv', 'avi', 'flv', 'mov', 'm4v', 'm3u8', 'webm', 'mpd'].includes(suffix)) {
          arrType.push('newMp4')
        } else if (['ppt', 'pptx'].includes(suffix)) {
          arrType.push('newPowerPoint')
        } else {
          arrType.push('other')
        }
      })

      const addParams = {
        encryptionStr: this.fileList[fileIndex].encryptionStr,
        titleArr: titleArr,
        sortNo: 1,
        username: this.$store.getters.name,
        arrType: arrType,
        dpathArr: dpathArr,
        kbId: this.fileList[fileIndex].kbId,
      }
      uploadChapterBatch(addParams).then((res) => {
        this.$message({
          message: res.message,
          type: 'success',
          duration: 1400
        })
        // this.fileList = []
        // this.$refs.upload.clearFiles()
        // this.$emit('uploadSuccess')
      }).catch(err => {
        // 还需删除上传的文件
        console.log(err)
      })
    }

  }
}
</script>
<style lang="scss" scoped>
.upload_main {
  padding:20px;
  width: 420px;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #dee0e3
}
.tip_text{
  color: #ef6135;
  // color: #42464d;
  font-size: 12px;
  line-height: 24px;
}
.top_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  .top_title {
    font-weight: 600;
    color: #2b2f36;
    line-height: 22px;
  }
  .top_btn {
    cursor: pointer;
    font-size: 20px;
    border-radius: 6px;
  }
}
.upload_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  padding: 5px 0;
  .file_icon {
    font-size: 16px;
    // margin-left: 22px;
    // height: 24px;
  }
  .upload_detail{
    display: flex;
    flex: 1;
    margin: 0 10px;
    flex-direction: column;
    overflow: hidden;
    .item_name {
      font-size: 14px;
      height: 20px;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .item_tip {
      font-size: 12px;
      height: 18px;
      line-height: 18px;
      color: #646a73;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .progress_icon {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
