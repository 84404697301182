import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '../store'
import { getPowerSystemCookie } from './auth'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_LOGIN_API,
  timeout: 18000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    console.log('config', config)
    const urlInfo = JSON.parse(sessionStorage.getItem('urlInfo'))
    if (config.url.indexOf('/login') !== -1) {

    } else {
      const urlInfo = getPowerSystemCookie() ? JSON.parse(getPowerSystemCookie()) : {}
      config.headers.Authorization = `Bearer ${urlInfo.token || ''}`
      config.headers.systemCode = process.env.VUE_APP_SYSTEM_CODE
      config.headers.hostIp = urlInfo.ip
    }

    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code !== '000000') {
      // 401:token过期或者校验不通过; 403:token校验成功,但是无权限访问;
      if (res.code === 401 || res.code === 403) {
        window.location.href = `${process.env.VUE_APP_POWER_SYSTEM_URL}?redirect=${window.location.href}`
      } else if (res.code === '000008' || res.code === '000005') {
        store.dispatch('SetUserStatus', '2')
        window.location.href = `${process.env.VUE_APP_POWER_SYSTEM_URL}?redirect=${window.location.href}`
      } else {
        Message({
          message: res.message,
          type: 'error',
          duration: 5 * 1000
        })
      }
      return Promise.reject(new Error('error'))
    } else {
      return response.data
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)
export default service
