<template>
  <div v-if="powerVisible">
    <el-dialog
      :visible="true"
      width="500px"
      title="编辑协作者"
      v-ismove
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="false"
      :before-close="closeTab">
      <div>
        <span>所有可访问此文件夹的用户</span>
        <div class="user-li-item" v-for="(item, index) in permissionUserList" :key="index">
          <div style="display: flex;">
            <span class="users" v-if="item.username">{{ item.username }}</span>
            <span class="users" v-else style="background:#3370ff">{{ item.dpName }}</span>
            <div class="name-dp-box">
              <span class="name-text">{{ item.workcode ? item.username : item.dpName }}</span>
              <span class="department-text">{{ item.workcode ? item.workcode : item.updateTime }}</span>
            </div>
          </div>
          <el-popover
            :ref="`popoverRef-`+index"
            placement="bottom-end"
            width="200"
            trigger="click">
            <div slot="reference">
              <span>{{ powerTypeList[item.permission - 1].name }}</span>
              <i class="el-icon-arrow-down"></i>
            </div>
            <div class="type-content-box">
              <div class="content-item-box" v-for="typeEl in powerTypeList" :class="{'isDisabled': getSelectDisable(typeEl.value) }" :key="typeEl.value" @click="updateUserPower(item, typeEl.value, index)">
                <span>{{ typeEl.name }}</span>
                <i v-if="item.permission === typeEl.value" class="el-icon-check" style="color:#409eff"></i>
              </div>
              <div @click="deleteClick(item, index)" class="content-item-box" style="color:red">移除</div>
            </div>
          </el-popover>
        </div>
      </div>
      <div slot="footer">
        <el-button size="small" type="primary" @click="closeTab">完成</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { setPersonPower, delPersonPower } from '@/api/space'

export default {
  data() {
    return {
      powerTypeList: [
        { value: 1, name: '可管理' },
        { value: 2, name: '可编辑' },
        { value: 3, name: '可阅读' }
      ]
    }
  },
  props: {
    powerVisible: {
      type: Boolean,
      default: false
    },
    permissionUserList: {
      type: Array,
      default: () => []
    },
    chapterId: {
      type: Number,
      default: null
    },
    listPermission: {
      type: Number,
      default: 3
    }
  },
  methods: {
    closeTab() {
      this.$emit('closePowerDialog')
    },
    getSelectDisable(val) {
      if (val === 1) {
        return this.listPermission !== 1 ? true : false
      } else if (val === 2) {
        return this.listPermission === 3 ? true : false
      } else {
        return false
      }
    },
    updateUserPower(person, permission, index) {
      const params = {
        userList: [],
        dpList: [],
        chapterId: person.chapterId,
        permission: permission
      }
      if (person.workcode) {
        params.userList.push({ workcode: person.workcode, username: person.username })
      } else {
        params.dpList.push({ departmentId: person.departmentId, dpName: person.dpName })
      }
      setPersonPower(params).then((res) => {
        if (res.code === '000000') {
          this.$message({
            message: res.message,
            type: 'success',
            duration: 1400
          })
          this.$refs[`popoverRef-` + index][0].doClose()
          this.$emit('confSuccess', permission, index)
        } else {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 1400
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    deleteClick(item, index) {
      this.$confirm('是否要移除此协作人?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delPersonPower({ idListStr: item.id }).then((res) => {
          if (res.code === '000000') {
            this.$message({
              message: res.message,
              type: 'success',
              duration: 1400
            })
            this.$emit('deleteSuccess', index)
          } else {
            this.$message({
              message: res.message,
              type: 'error',
              duration: 1400
            })
          }
        }).catch(err => {
          console.log(err)
        })
      }).catch((err) => {
        console.log(err)
        this.$message({
          type: 'info',
          message: '已取消移除操作'
        })
      })
    }
  }
}
</script>
<style scoped lang="scss">
.user-li-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  padding: 10px 0;
  justify-content: space-between;
  .users {
    width: 32px;
    height: 32px;
    font-size: 12px;
    line-height: 32px;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    margin-right: 10px;
    background: #f18606;
    color: #fff;
  }
  .name-dp-box {
    display: flex;
    flex-direction: column;
    .name-text {
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      color: #1f2329;
      font-size: 14px;
      line-height: 20px;
      word-break: break-all;
      word-wrap: break-word;
    }
    .department-text {
      color: #646a73;
      font-size: 12px;
      line-height: 16px;
      max-width: 280px;
      overflow: hidden;
    }
  }
}
.type-content-box {
  padding: 10px;
  .content-item-box {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    /* line-height: 24px; */
    padding: 7px 20px 7px 10px;
    color: #1f2329;
    align-items: center;
    &:hover {
      background: #dfe1e4;
    }
    &.isDisabled {
      color: #c0c4cc;
      cursor: not-allowed;
      &:hover {
        background: #fff;
      }
    }
  }
}
</style>
