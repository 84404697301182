<template>
  <div class="nav-tree-group">
    <ul class="nav-tree">
      <li class="nav-tree-item" v-for="(item,index) in treeData" :key="index">
        <TreeNavItem :treeData="item" @holdClick="holdClick"></TreeNavItem>
      </li>
    </ul>
  </div>
</template>
<script>
import TreeNavItem from './treeNavItem'
export default {
  name: 'TreeNav',
  props: {
    treeData: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  components: {
    TreeNavItem
  },
  methods: {
    holdClick(tree) {
      this.$store.dispatch('setCatalogTree', tree)
    }
  }
}
</script>
<style lang="scss" scoped>
.nav-tree{
  list-style-type: none;
  padding-left: 0;
  padding-right: 8px;
}
.nav-tree-item{
  margin-bottom:0.2rem;
}
</style>
