import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '@/store'
import { getPowerSystemCookie } from './auth'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 15000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    const urlInfo = getPowerSystemCookie() ? JSON.parse(getPowerSystemCookie()) : {}
    config.headers.Authorization = `Bearer ${urlInfo.token || ''}`
    config.headers.systemCode = process.env.VUE_APP_SYSTEM_CODE
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code !== '000000') {
      if (res.code === 401 || res.code === 403) {
        window.location.href = `${process.env.VUE_APP_POWER_SYSTEM_URL}?redirect=${window.location.href}`
      }else if (res.code === '000008' || res.code === '000005') {
        store.dispatch('SET_USERSTATUS', false)
        store.dispatch('SetUserStatus', '2')
        window.location.href = `${process.env.VUE_APP_POWER_SYSTEM_URL}?redirect=${window.location.href}`
      } else {
        Message({
          message: res.message,
          type: 'error',
          duration: 5 * 1000
        })
      }
      return Promise.reject(new Error('error'))
    } else {
      return response.data
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
