<template>
  <div class="app-wrapper">
    <sidebar class="sidebar-left"/>
    <div class="main-containers">
      <navbar1/>
      <app-main/>
    </div>
  </div>
</template>

<script>
import { AppMain } from './components'
import sidebar from './sidebar.vue'
import ResizeMixin from './mixin/ResizeHandler'
import navbar1 from './components/navbar1.vue'

export default {
  name: 'Layout',
  components: {
    sidebar,
    AppMain,
    navbar1,
  },
  mixins: [ResizeMixin],
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar
    },
    device() {
      return this.$store.state.app.device
    }
  },
  methods: {
  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.app-wrapper {
  display: flex;
  flex-direction: row !important;
  min-width: auto;
  min-height: auto;
  height: 100%;
  .sidebar-left {
    display: flex;
    flex-direction: column;
    // background: #f2f3f5;
    background: linear-gradient(to bottom, #e5ebf8, #f2f3f5);
    background: -webkit-linear-gradient(to bottom, #e5ebf8, #f2f3f5);
    background: -moz-linear-gradient(to bottom, #e5ebf8, #f2f3f5);
    background: -o-linear-gradient(to bottom, #e5ebf8, #f2f3f5);
    position: relative;
  }
  .main-containers {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    min-width: 540px;
  }
}
</style>
