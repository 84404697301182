<template>
  <div class="nav-tree-item-group">
    <div class="nav-tree-item-name ellipsis" @click="toggleCheck(treeData)" v-if="(treeData.isFirst && treeData.hasArticle===0) || !treeData.isFirst">
      <span class="opt-btn" v-if="!isOpen && treeData.hasArticle === 0 && (['home','knowledge','collect','recycle'].indexOf(treeData.value)===-1)" @click.stop="toggleTree(treeData)">
        <svg-icon icon-class="open-btn" style="width:0.8em;height:0.8em"></svg-icon>
      </span>
      <span class="opt-btn" v-else-if="isOpen && treeData.hasArticle === 0 && (['home','knowledge','collect','recycle'].indexOf(treeData.value)===-1)" @click.stop="toggleTree(treeData)">
        <svg-icon icon-class="close-btn" style="width:0.8em;height:0.8em"></svg-icon>
      </span>
      <span v-if="treeData.hasArticle === 1 || (['home','knowledge','collect','recycle'].indexOf(treeData.value)!==-1)" class="opt-btn">
        <span class="zw"></span>
      </span>
      <span v-if="treeData.hasArticle === 1" class="opt-btn">
        <svg-icon v-if="!treeData.type" icon-class="markdown_icon"></svg-icon>
        <svg-icon v-else-if="treeData.type==='newExcel'" icon-class="excel_icon"></svg-icon>
        <svg-icon v-else-if="treeData.type==='newPowerPoint'" icon-class="ppt"></svg-icon>
        <svg-icon v-else-if="treeData.type==='newMp4'" icon-class="mp4"></svg-icon>
        <svg-icon v-else-if="treeData.type==='newDoc'" icon-class="word"></svg-icon>
        <svg-icon v-else icon-class="word"></svg-icon>
      </span>
      <span class="sub-tree-item ellipsis" :class="treeData.active?'active':''">
        <svg-icon v-if="treeData.id === -1" :icon-class="treeData.icon"></svg-icon>
        <svg-icon v-else-if="treeData.hasArticle === 0" icon-class="folders"></svg-icon>
        <span class="ellipsis title">{{treeData.name}}</span>
      </span>
      <span class="zz"></span>
      <span class="spark-right" v-if="isTree && (treeData.value==='my' || treeData.id !== -1)">
        <!-- 顶级菜单-我的空间 右侧按钮 -->
        <el-tooltip v-if="treeData.specialIcon==='add'" class="item" effect="dark" content="新建文件夹" placement="right">
          <svg-icon :icon-class="treeData.specialIcon" @click="addFolderClick"></svg-icon>
        </el-tooltip>
        <template v-else>
          <right-operate
            :treeData="treeData"
            @addFolderSuccess="addFolderSuccess"
            @deleteSuccess="deleteSuccess"
            @addFolderClick="addFolderClick">
          </right-operate>
        </template>
      </span>
    </div>
    <ul class="nav-tree-item-subTree" v-show="isOpen" v-if="isTree">
      <li v-for="(item,index) in treeData.subTree" :key="index">
        <TreeNavItem :treeData="item" @holdClick="holdClick"></TreeNavItem>
      </li>
    </ul>

    <!-- 添加文件夹 弹窗 -->
    <folder-add
      :visible="visible"
      :parentId="Number(treeData.id)"
      @closeTab="closeTab"
      @addFolderSuccess="addFolderSuccess">
    </folder-add>
  </div>
</template>
<script>
import rightOperate from '@/components/rightOperate'
import { selectChapterByParentId, selectRecycleList, getCooperationList } from '@/api/space'
import folderAdd from '@/components/addFolderDialog/index'
import { newFile } from '@/api/fileApi'

export default {
  components: {
    rightOperate,
    folderAdd
  },
  name: 'TreeNavItem',
  data: function() {
    return {
      isOpen: false,
      visible: false
    }
  },
  props: {
    treeData: {
      type: Object,
      default: function() {
        return []
      }
    }
  },
  computed: {
    isTree() {
      return this.treeData.hasArticle === 0
    }
  },
  mounted() {
    if (this.treeData.hasArticle === 0) {
      console.log('treeData', this.treeData)
    }
  },
  // floderView里面传了这个值 用了一个共同组件所以要传
  provide() {
    return {
      floderThis: null
    }
  },
  methods: {
    toggleTree(item) {
      if (this.isTree) {
        this.isOpen = !this.isOpen
      }
      if (this.isOpen) {
        if (item.value === 'cooperation') {
          this.getCooperationData()
        } else {
          this.getSubList()
        }
      }
    },
    getSubList() {
      const regions = ['my', 'cooperation', 'sharedme', 'knowledge', 'collect', 'recycle'] // 判断不同的目录，请求对应类型的数据
      const site = regions.indexOf(this.treeData.value.split('-')[0])
      selectChapterByParentId({
        encryptionStr: this.treeData.encryptionStr,
        region: regions[site] === 'cooperation' ? 'sharedme' : regions[site]
      }).then((res) => {
        if (res.code === '000000' && res.data) {
          res.data.forEach(item => {
            item.value = regions[site] + '-' + item.id
            item.isFirst = this.treeData.encryptionStr === '-1'
          })
          this.$nextTick(() => {
            this.treeData.subTree = res.data
          })
          this.$forceUpdate()
        } else {
          this.treeData.subTree = []
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getRecycleSubList() {
      selectRecycleList({
        encryptionStr: this.treeData.encryptionStr,
        region: 'recycle'
      }).then((res) => {
        if (res.code === '000000' && res.data) {
          res.data.forEach(item => {
            item.value = 'recycle-' + item.id
          })
          this.$nextTick(() => {
            this.treeData.subTree = res.data
          })
          this.$forceUpdate()
        } else {
          this.treeData.subTree = []
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 获取协作的节点列表
    getCooperationData() {
      getCooperationList({ dpIdStr: this.$store.getters.udId.replaceAll('-', ',') }).then((res) => {
        if (res.code === '000000' && res.data) {
          res.data.forEach(item => {
            item.value = 'cooperation-' + item.id
            item.isFirst = this.treeData.encryptionStr === '-1'
          })
          this.$nextTick(() => {
            this.treeData.subTree = res.data
          })
          this.$forceUpdate()
        } else {
          this.treeData.subTree = []
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // getParentSubList(val) {
    //   if (val && item.id == val) {
    //     this.$emit('getParentSubList', val)
    //   }
    // },
    toggleCheck(item) {
      console.log('值', item)
      // id=-1 是顶层目录
      if (item.id === -1) {
        // if (item.value !== this.$store.getters.catalog) {
        this.$store.dispatch('toggleCatalog', item.value)
        this.$router.push({
          path: item.router
        })
        // }
        // 回调保存点击的树形名称
        this.$emit('holdClick', [item.name])
      } else {
        // 处理folder
        if (item.hasArticle === 0) {
          if (item.value && item.value.indexOf('my-') !== -1) {
            // 我的空间点击
            this.$router.push({
              path: '/drive/folder/' + item.encryptionStr
            })
          } else {
            // 与我分享
            this.$router.push({
              path: '/drive/share/folder/' + item.encryptionStr
            })
          }
          // 回调保存点击的树形名称
          this.$emit('holdClick', [item.name])
          this.$store.dispatch('toggleCatalog', item.value)
        } else {
          // 文章或文件
          if (item.type) {
            // 上传的文件
            const Base64 = require('js-base64').Base64
            const urlCode = Base64.encode(sessionStorage.getItem('urlInfo'))
            if (item.type === 'newMp4' || item.type === 'other') {
              newFile({
                action: 'getFileDetail',
                code: urlCode,
                path: item.dpath
              }).then((res) => {
                if (item.type === 'other') {
                  window.open(res.data.url, '_blank')
                } else {
                  // 在新路由页面打开视频播放
                  const { href } = this.$router.resolve({
                    path: `/wiki/player?name=${res.data.detail.name}&url=${res.data.url}`
                  })
                  window.open(href, '_blank')
                }
              }).catch(err => {
                console.log(err)
              })
            } else {
              const href = `${process.env.VUE_APP_PHP_BASE_API}/api.php?action=onlyOfficeEdit&path=` + item.dpath + `&code=${urlCode}`
              window.open(href, '_blank')
            }
          } else {
            // Markdown文章
            if (item.value && item.value.indexOf('my-') !== -1) {
              // 我的空间点击
              const { href } = this.$router.resolve({ path: '/wiki/article/' + item.encryptionStr })
              window.open(href, '_blank')
            } else {
              // 与我分享 点击
              const { href } = this.$router.resolve({ path: '/wiki/article/' + item.encryptionStr })
              window.open(href, '_blank')
            }
          }
        }
      }
    },
    addFolderClick() {
      this.visible = true
    },
    closeTab() {
      this.visible = false
    },
    addFolderSuccess() {
      this.visible = false
      this.getSubList()
      // 如果是已选中的
      if (this.treeData.value === this.$store.getters.catalog) {
        // || this.treeData.name === this.$store.getters.catalogTree.slice(-1)[0]
        this.$EventBus.$emit('getListEvent')
      }
    },
    holdClick(tree) {
      this.$emit('holdClick', [this.treeData.name, ...tree])
    },
    deleteSuccess() {
      this.getRecycleSubList()
      // this.getParentSubList(item.parentId)
    }
  }
}
</script>
<style lang="scss" scoped>
.nav-tree-item-group {
  width: calc(100% - 8px);
  margin-left: 8px;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    // background-color: #e6e8eb;
    // background-color: #d5def0;
  }
  .nav-tree-item-name {
    line-height: 30px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    .opt-btn {
      display: block;
      width: 19px;
      height: 24px;
      .zw {
        display: flex;
        height: 100%;
        width: 19px;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
      }
    }
    .sub-tree-item {
      display: flex;
      align-items: center;
      &.active {
        color: #3370ff;
      }
    }
    .title {
      display: block;
      padding-left: 5px;
      font-size: 14px;
      user-select: none;
    }
    .zz {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
      font-size: 18px;
    }
    .spark-right {
      display: none;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 36px;
      border-radius: 6px;
      margin-left: auto;
      &:hover {
        background-color: #a1bbd3;
      }
    }
    &:hover {
      background: #d5def0;
      >.spark-right {
        display: block;
      }
    }
  }
}
.nav-tree-item-subTree{
  list-style-type: none;
  padding-left:0;
}
.nav-tree-item-subTree > li{
  margin-bottom:0.2rem;
  margin-left:5px;
}
.nav-tree-item-name{
  margin-left: 5px;
  position: relative;
}
.weizhi {
  display: inline-block;
  width: 16px;
  height: 16px;
}
.right-panel {
  .operate-item {
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    margin: 0 5px;
    cursor: pointer;
    display: flex;
    &:hover {
      background-color: #ececee;
    }
    .icon {
      display: block;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 40px;
      margin-right: 5px;
      .svg-icon {
        width: 1.2em;
        height: 1.2em;
      }
      &.rightIcon {
        margin-left: auto;
      }
    }
    .operate {
      display: flex;
      align-items: center;
    }
  }
}
.el-divider--horizontal {
  margin: 5px 0;
}
</style>
