export default {
  route: {
    index: '首页'
  },
  navbar: {
    logOut: '退出登录',
    index: '首页'
  },
  login: {
    title: '三态股份',
    logIn: '登录',
    username: '账号',
    password: '密码'
  }
}
